import React, { useCallback, useEffect, useState } from 'react';
import useStyles from './styles';
import Button from '../../components/Button';
import { Strings } from '../../utils/strings';
import { useTheme } from '@mui/material/styles';
import NextPrevious from '../../components/NextPreviousButton';
import TableToolbox from '../../components/TableToolbox';
import { connect } from 'react-redux';
import { fetchAnnouncementList } from '../../redux/actions/adminActions/announcementList';
import {
  formatingPhoneNumber,
  getDateRangeValue,
  getErrorMessage,
  getLocaleStorageVal,
  isAdmin,
  isClient,
  issueStatusArray,
  isSuperAdmin,
  manageNotesHref,
  setLocaleStorageVal,
} from '../../utils/appUtils';
import { Avatar } from '@material-ui/core';
import { CircularProgress, Divider, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Dropdown from '../../components/Dropdown';
import IssuesContainer from '../../components/IssuesContainer';
import CommonProgressCard from '../../components/DashboardCommonCard';
import FeedBackContainer from '../../components/FeedbackContainer';
import classNames from 'classnames';
import { icons } from '../../utils/icons';
import Tabs from '../../components/Tabs';
import { useAlert } from 'react-alert';
import NewArrivalDeparture from './ArrivalDepartureForm';
import { fetchJuncturesList } from '../../redux/actions/clientActions/juncturesList';
import moment from 'moment';
import TitleContainer from '../../components/TitleContainer';
import IssuesFilter from '../Issues/IssuesFilter';
import { fetchSuppliesNeeded } from '../../redux/actions/adminActions/suppliesNeeded';
import { fetchTeamDiscussion } from '../../redux/actions/adminActions/teamDiscussion';
import { fetchDashboardIssueList } from '../../redux/actions/adminActions/dashboardIssueList';
import { updateJuncture } from '../../redux/actions/clientActions/updateJuncture';
import { fetchUserListByType } from '../../redux/actions/adminActions/getUserListByType';
import { fetchClientsAvailability } from '../../redux/actions/adminActions/getClientsAvailability';
import { fetchLatestReport } from '../../redux/actions/clientActions/latestReportList';
import { fetchIssueList } from '../../redux/actions/jobActions/issuesActions/issueList';
import { fetchClientAdmin } from '../../redux/actions/clientActions/dashboardGetAdmin';
import { fetchDashboardCount } from '../../redux/actions/commonActions/dashboardCount';
import { fetchSuperAdminDashboardIssueList } from '../../redux/actions/adminActions/superAdminDashboardIssueList';
import { fetchAdminJunctureList } from '../../redux/actions/adminActions/adminJuncturesList';
import { fetchClientFeedbackList } from '../../redux/actions/adminActions/clientFeedbackList';
import { exportJunctures } from '../../redux/actions/commonActions/exportJunctures';
import SendManagementFeedback from './SendManagementFeedback';
import { listFilter } from '../../redux/actions/filterActions/listFilter';
import { deleteJuncture } from '../../redux/actions/clientActions/deleteJuncture';
import IssuesNewFilter from '../Issues/IssuesFilter/IssuesNewFilter';
import CommonDialog from '../../components/CommonDialog';
import ManageEBlast from './ManageEBlast';
import { fetchEBlast } from '../../redux/actions/adminActions/getEBlast';
import WeatherWidget from '../../components/WeatherWidget';
import { getForecastWeather } from '../../redux/actions/clientActions/getForecastWeather';
import { getCurrentWeather } from '../../redux/actions/clientActions/getCurrentWeather';
import { Swiper, SwiperSlide } from 'swiper/react';
import { clearAllClientFeedback } from '../../redux/actions/adminActions/clearAllClientFeedback';
// import required modules
import { Pagination, Navigation } from 'swiper';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { addMyNote } from '../../redux/actions/adminActions/addMyNote';
import debounce from 'lodash/debounce';
import CustomizedTooltips from '../../components/CustomizedTooltips';
import { downloadSupplies } from '../../redux/actions/adminActions/downloadSupplies';
import { Colors } from '../../utils/color';

const issueDataStore = 'DashboardIssueData';

const EditorModules = {
  toolbar: [
    // [{ header: '1' }, { header: '2' }, { font: [] }],
    // [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike'], // 'blockquote'
    [
      { list: 'ordered' },
      { list: 'bullet' },
      // { indent: '-1' },
      // { indent: '+1' },
    ],
    ['link'], // 'image', 'video'
    // ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
const EditorFormats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
];

const CommonProfileLabel = (props) => {
  const { title, titleValue, href } = props;
  const classes = useStyles();
  return (
    <label className={classes.common_text} style={{ marginBottom: '5px' }}>
      {title && `${title} : `}
      {titleValue && (
        <a href={href || '#'} className={classes.text_blue}>
          {titleValue || '-'}
        </a>
      )}
    </label>
  );
};

const CommonLoader = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div className={classes.flexView} style={props.style}>
      <CircularProgress
        style={{
          color: theme.palette.background_button,
        }}
      />
    </div>
  );
};

const CommonNoDataFound = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.flexView} style={props.style}>
      <p className={classes.no_data_message}>
        {props?.text ? props?.text : Strings.NO_DATA_FOUND}
      </p>
    </div>
  );
};

const AnnouncementView = (props) => {
  const {
    classes,
    onClickAllAnnouncement,
    onNextClick,
    onPreviousClick,
    loader,
  } = props;
  const [quillData, setQuillData] = useState({ theme: 'snow' });

  useEffect(() => {
    manageNotesHref('announcementNote');
  }, [onNextClick, onPreviousClick]);

  return (
    <CommonBoxView
      containerClassName={classNames(
        classes.client_announcement_box,
        props.boxContainer && props.boxContainer
      )}
      childrenBoxstyle={{ marginBottom: isAdmin() && 25 }}
      headerChildren={
        <div
          className={classNames(
            classes.announcement_header,
            classes.headerContainer && classes.headerContainer
          )}
        >
          {props.isData && !loader && (
            <>
              {!isAdmin() && (
                <NextPrevious
                  onNextClick={onNextClick}
                  onPreviousClick={onPreviousClick}
                />
              )}
              <span
                htmlFor='title'
                className={classes.announcement_title}
                style={{ marginLeft: isAdmin() ? 0 : 8 }}
              >
                {props.title}
              </span>
              {isAdmin() && (
                <NextPrevious
                  onNextClick={onNextClick}
                  onPreviousClick={onPreviousClick}
                  next_previous_style={{ marginLeft: 'auto' }}
                />
              )}
            </>
          )}
        </div>
      }
    >
      {loader ? (
        <CommonLoader style={{ width: '100%', height: '100%' }} />
      ) : props?.isData && !loader ? (
        <>
          {/* <ReactQuill
            theme={quillData.theme}
            modules={{ toolbar: false }}
            formats={EditorFormats}
            value={props?.message}
            readOnly
            className={classes.customQuillContainer}
          /> */}
          <div
            id='announcementNote'
            className={classes.common_text}
            style={{
              marginTop: 12,
              marginBottom: 0,
            }}
            dangerouslySetInnerHTML={{
              __html: props?.message,
            }}
          />

          {isAdmin() && (
            <label
              className={classes.text_view_list}
              onClick={onClickAllAnnouncement}
            >
              {Strings.VIEW_ALL_ANNOUNCEMENTS}
            </label>
          )}
        </>
      ) : (
        <div
          className={classes.flexView}
          style={{ width: '100%', height: '100%' }}
        >
          <p className={classes.no_data_message}>
            {Strings.NO_ANNOUNCEMENT}
            {isAdmin() && (
              <span
                className={classes.view_all_txt}
                onClick={onClickAllAnnouncement}
              >
                {' View All'}
              </span>
            )}
          </p>
        </div>
      )}
    </CommonBoxView>
  );
};

const CommonBoxView = (props) => {
  const { children, headerChildren, containerClassName } = props;
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div
      className={classNames(
        classes.commonBorderBox,
        containerClassName && containerClassName
      )}
    >
      {(props.listTitle ||
        props.isPrintIcon ||
        props.isTabView ||
        props.isDate ||
        headerChildren) && (
        <div className={classes.commonBoxHeader}>
          {props.listTitle && (
            <span
              onClick={() => props.handleTitleClick && props.handleTitleClick()}
              style={{ cursor: props.handleTitleClick ? 'pointer' : 'auto' }}
              className={classes.common_header_text}
            >
              {props.listTitle}&nbsp;
              {props.dataLength && `(${props.dataLength})`}
            </span>
          )}
          {(props.isAddIcon ||
            props.isDownloadIcon ||
            props.isPrintIcon ||
            props.isArrivalIcon ||
            props.isDepartureIcon) && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'end',
              }}
            >
              {props.isArrivalIcon && (
                <div
                  className={classes.flex_view}
                  style={{
                    alignItems: 'flex-start',
                    marginRight: 10,
                    gridTemplateColumns: 'none',
                  }}
                >
                  <CustomizedTooltips title={'Sort Arrival'}>
                    <img
                      src={icons(theme).arrivalIcon}
                      alt=''
                      className={classes.view_icon}
                      onClick={props.onSortArrivalClick}
                    />
                  </CustomizedTooltips>
                </div>
              )}
              {props.isDepartureIcon && (
                <div
                  className={classes.flex_view}
                  style={{
                    alignItems: 'flex-start',
                    marginRight: 10,
                    gridTemplateColumns: 'none',
                  }}
                >
                  <CustomizedTooltips title={'Sort Departure'}>
                    <img
                      src={icons(theme).departuresIcon}
                      alt=''
                      className={classes.view_icon}
                      onClick={props.onSortDepttureClick}
                    />
                  </CustomizedTooltips>
                </div>
              )}
              {props.isAddIcon && (
                <div
                  className={classes.flex_view}
                  style={{
                    alignItems: 'flex-start',
                    marginRight: 10,
                    gridTemplateColumns: 'none',
                  }}
                >
                  <CustomizedTooltips title={'Add'}>
                    <img
                      src={icons(theme).Plus}
                      alt=''
                      className={classes.view_icon}
                      onClick={props.onAddClick}
                    />
                  </CustomizedTooltips>
                </div>
              )}
              {props.isDownloadIcon && (
                <div
                  className={classes.flex_view}
                  style={{
                    alignItems: 'flex-start',
                    gridTemplateColumns: 'none',
                  }}
                >
                  <CustomizedTooltips title={'Download'}>
                    <img
                      src={icons(theme).downloadIcon}
                      alt=''
                      className={classes.view_icon}
                      onClick={props.onDownloadClick}
                    />
                  </CustomizedTooltips>
                </div>
              )}
              {props.isPrintIcon && (
                <div
                  className={classes.flex_view}
                  style={{
                    alignItems: 'flex-end',
                    gridTemplateColumns: 'none',
                  }}
                >
                  <CustomizedTooltips title={'Print'}>
                    <img
                      src={icons(theme).printIcon}
                      alt=''
                      className={classes.view_icon}
                      onClick={props.onPrintClick}
                    />
                  </CustomizedTooltips>
                </div>
              )}
            </div>
          )}
          {props.isTabView && (
            <Tabs
              underlineTabs
              activeTabUnderline
              containerClassName={classes.dashboard_tab_container}
              tabClassName={classes.underline_tab}
              dataList={props.tabArray}
              activeTab={props.activeTab}
              onChange={(value) => props.setActiveTab(value)}
              notScroll
            />
          )}
          {props.isDate && (
            <span className={classes.common_text}>{props.date}</span>
          )}
          {headerChildren && headerChildren}
        </div>
      )}
      <div className={classes.commonBoxContent} style={props.childrenBoxstyle}>
        {children}
      </div>
    </div>
  );
};

const UpcomingArrivalListView = (props) => {
  const classes = useStyles();
  return (
    <>
      {props.dataList?.map((data, index) => (
        <div key={index}>
          <div className={classes.list_container}>
            <img
              src={icons(props.theme).editIcon}
              alt=''
              className={classes.icons}
              onClick={() => {
                props.setIsEdit(true);
                props.setShowArrivalDialog(true);
                props.setSelectedArrivalDeparture(data);
                props.setIsAdminsDashboardOnly(true);
              }}
            />
            <img
              src={icons(props.theme).deleteIcon}
              alt=''
              className={classes.icons}
              onClick={() => {
                props.setShowDeleteJunctureModal(true);
                props.setSelectedJunctureId(data._id);
              }}
            />
          </div>
          <div className={classes.upcoming_data_container}>
            <div className={classes.flex_view} style={{ flex: 1 }}>
              <Avatar
                src={data?.client?.profilePicture}
                style={{ height: 32, width: 32 }}
                alt=''
              />
              <div className={classes.flex_column_view}>
                <span className={classes.text_bold}>
                  {data.client?.name || ''}
                </span>
              </div>
            </div>
            <div className={classes.flex_column_view} style={{ flex: 1 }}>
              <div className={classes.flex_view}>
                <img src={icons().arrivalIcon} alt='' />
                <span className={classes.text_bold}>{Strings.ARRIVAL}</span>
              </div>
              <span className={classes.text_blue}>
                {data?.arrival
                  ? moment(data?.arrival).format('MMM DD, YYYY')
                  : 'TBD'}
              </span>
            </div>
            <div className={classes.flex_column_view} style={{ flex: 1 }}>
              <div className={classes.flex_view}>
                <img src={icons().departuresIcon} alt='' />
                <span className={classes.text_bold}>{Strings.DEPARTURES}</span>
              </div>
              <span className={classes.text_red}>
                {data?.departure
                  ? moment(data?.departure).format('MMM DD, YYYY')
                  : 'TBD'}
              </span>
            </div>
          </div>
          <Stack
            pb={data?.arrivalNotes || data?.departureNotes ? 2 : 0}
            className={classes.borderBottomStyle}
          >
            <Stack gap={1.25}>
              {data?.arrivalNotes ? (
                <Stack gap={0.5}>
                  <Typography lineHeight='normal' className={classes.text_bold}>
                    {Strings.ARRIVAL_NOTES}
                  </Typography>
                  <Typography className={classes.arrivalSubText}>
                    {data?.arrivalNotes}
                  </Typography>
                </Stack>
              ) : null}
              {data?.departureNotes ? (
                <Stack gap={0.5}>
                  <Typography lineHeight='normal' className={classes.text_bold}>
                    {Strings.DEPARTURE_NOTES}
                  </Typography>
                  <Typography className={classes.arrivalSubText}>
                    {data?.departureNotes}
                  </Typography>
                </Stack>
              ) : null}
            </Stack>
          </Stack>
        </div>
      ))}
    </>
  );
};

const ArrivalDepartureView = (props) => {
  const { dateTime, action, onClick, classes } = props;
  const date = moment(dateTime).format('MMM D, YYYY');
  const time = moment(dateTime).format('h:mmA');
  let dateAndTime;
  if (!isClient()) {
    dateAndTime = dateTime ? date + ' at ' + time : 'TBD';
  } else {
    dateAndTime = dateTime ? date : 'TBD';
  }

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center'>
      <div className={classes.flex_view}>
        <img
          src={icons().noteClockIcon}
          alt=''
          className={classes.clock_icon}
        />
        <span className={classes.common_text}>{dateAndTime}</span>
        <span className={classes.text_bold}>{action}</span>
      </div>

      {/* <label
        className={classes.text_blue}
        style={{ cursor: 'pointer' }}
        onClick={onClick}
      >
        {Strings.EDIT}
      </label> */}
    </Stack>
  );
};

const LatestReportBox = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div className={classes.report_box}>
      <div className={classes.flex_column_view}>
        <span className={classes.report_title_text}>{props.title}</span>
        <span className={classes.common_text}>
          {'on ' + props.date + ' by ' + props.userName}
        </span>
      </div>
      <div className={classes.report_view_button_container}>
        <img
          src={icons(theme).eyeIcon}
          alt=''
          className={classes.view_icon}
          onClick={props.onViewClick}
        />
      </div>
    </div>
  );
};

const PropertyContainer = (props) => {
  const classes = useStyles();
  return (
    <>
      {props.loading ? (
        <CommonLoader />
      ) : props.data?.length === 0 ? (
        <CommonNoDataFound />
      ) : (
        props.data?.map((data, index) => (
          <div className={classes.property_container} key={index}>
            <label className={classes.property_title}>{data.property}</label>
            <label className={classes.property_subText}>
              {Strings.CLIENT + ': ' + data.client}
            </label>
          </div>
        ))
      )}
    </>
  );
};

const Dashboard = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const navigate = useNavigate();
  const existingIssuesData = getLocaleStorageVal(issueDataStore) ?? {};
  const [announcementList, setAnnouncementList] = useState([]);
  const [count, setCount] = useState(0);
  const [activeTab, setActiveTab] = useState('Here');
  const [showArrivalDialog, setShowArrivalDialog] = useState(false);
  const [
    showSendManagementFeedbackDialog,
    setShowSendManagementFeedbackDialog,
  ] = useState(false);
  const [juncturesList, setJuncturesList] = useState([]);
  const [activeIssueTab, setActiveIssueTab] = useState('all');
  const [selectedIssueStatus, setSelectedIssueStatus] = useState('all');
  const userName = localStorage.getItem('userName');
  const [suppliesNeeded, setSuppliesNeeded] = useState([]);
  const [teamDiscussion, setTeamDiscussion] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedArrivalDeparture, setSelectedArrivalDeparture] = useState({});
  const [isAdminsDashboardOnly, setIsAdminsDashboardOnly] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [selectedClient, setSelectedClient] = useState('all');
  const [latestReportList, setLatestReportList] = useState([]);
  const [clientAdminData, setClientAdminData] = useState({});
  const [dashboardCount, setDashboardCount] = useState({});
  const [openIssuesFilter, setOpenIssuesFilter] = useState(false);
  const [filter, setFilter] = useState(null);
  const [adminJunctureList, setAdminJunctureList] = useState([]);
  const [clientFeedbackList, setClientFeedbackList] = useState([]);
  const [filterDropdownValue, setFilterDropdownValue] = useState('');
  const [filterDropdownList, setFilterDropdownList] = useState([]);
  const [openEditFilterDialog, setOpenEditFilterDialog] = useState({});
  const [showDeleteJunctureModal, setShowDeleteJunctureModal] = useState(false);
  const [selectedJunctureId, setSelectedJunctureId] = useState('');
  const [isFilterChange, setIsFilterChange] = useState(false);
  const [openEBlastModal, setOpenEBlastModal] = useState(false);
  const [eBlastButtonText, setEBlastButtonText] = useState('');
  const [eBlastUrl, setEBlastUrl] = useState('');
  const [coords, setCoords] = useState({});
  const [notificationType, setNotificationType] = useState('');
  const [fetchLatLngLoader, setFetchLatLngLoader] = useState(true);
  const [quillData, setQuillData] = useState({ theme: 'snow' });
  const [quillValue, setQuillValue] = useState(``);
  const [sortOrder, setSortOrder] = useState('asc');
  const [openClearNoteDialog, setOpenClearNoteDialog] = useState(false);
  const [openClearFeedbackModal, setOpenClearFeedbackModal] = useState(false);
  const [dueSortType, setDueSortType] = useState(
    existingIssuesData?.sortBy
      ? existingIssuesData?.sortBy?.value === 1
        ? 'asc'
        : 'desc'
      : 'desc'
  );

  const issueListData = props.dashboardIssueListData || {};
  const clientAvailabilityData = props.clientsAvailabilityData;

  const clientIssueListData = props.issueListData || [];
  // const isMdDown = useMediaQuery('(max-width:960px)');
  const superAdminIssueListData = props.superAdminDashboardIssueListData || [];

  const issuesTabArray = [
    { id: 'all', name: 'ALL', length: issueListData?.all || 0 },
    { id: 'pastDue', name: 'PAST DUE', length: issueListData?.pastDue || 0 },
    { id: 'dueToday', name: 'DUE TODAY', length: issueListData?.dueToday || 0 },
    {
      id: 'dueWeek',
      name: 'DUE THIS WEEK',
      length: issueListData?.dueWeek || 0,
    },
    { id: 'Critical', name: 'CRITICAL', length: issueListData?.critical || 0 },
  ];

  const tabsArray = [
    { id: 'Here', name: 'HERE', length: clientAvailabilityData?.HERE || 0 },
    { id: 'Away', name: 'AWAY', length: clientAvailabilityData?.AWAY || 0 },
  ];

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  const onSuccess = (msg) => {
    if (msg) {
      alert.success(msg);
    }
  };

  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  function success(pos) {
    var crd = pos.coords;

    let data = {
      lat: crd?.latitude,
      lng: crd?.longitude,
    };
    setCoords(data);
    setFetchLatLngLoader(false);
    // console.log('Your current position is:');
    // console.log(`Latitude : ${crd.latitude}`);
    // console.log(`Longitude: ${crd.longitude}`);
    // console.log(`More or less ${crd.accuracy} meters.`);
  }

  function errors(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
    setFetchLatLngLoader(false);
  }

  const grantPermission = () => {
    navigator.geolocation.getCurrentPosition(success, errors, options);
  };

  useEffect(() => {
    if (navigator?.geolocation && isClient()) {
      navigator?.permissions
        ?.query({ name: 'geolocation' })
        .then(function (result) {
          if (result.state === 'granted') {
            navigator.geolocation.getCurrentPosition(success, errors, options);
          }
          setNotificationType(result.state);
          setTimeout(() => {
            setFetchLatLngLoader(false);
          }, 5000);
          // if (result.state === 'granted') {
          //   // navigator.geolocation.getCurrentPosition(success);
          //   //If granted then you can directly call your function here
          // } else if (result.state === 'prompt') {
          //   // console.log(result.state);
          //   // navigator.geolocation.getCurrentPosition(success, errors, options);
          // } else if (result.state === 'denied') {
          //   console.log('result.state', result.state)
          //   //If denied then you have to show instructions to enable location
          // }
          result.onchange = function () {
            setNotificationType(result.state);
            if (result.state === 'granted') {
              setFetchLatLngLoader(true);
            }
          };
        });
    } else {
      setTimeout(() => {
        setFetchLatLngLoader(false);
      }, 5000);
    }
  }, []);

  useEffect(() => {
    if (isClient()) {
      props.getForecastWeather(onError);
      props.getCurrentWeather(onError);
    }
  }, [coords]);

  // useEffect(() => {
  //   if (Object.keys(coords).length > 0) {
  //     props.getForecastWeather(coords, onError);
  //     props.getCurrentWeather(coords, onError);
  //   }
  // }, [coords]);

  useEffect(() => {
    props.fetchDashboardCount((err) => onError(err));

    if (isAdmin()) {
      props.fetchSuppliesNeeded((err) => onError(err));
      props.fetchTeamDiscussion((err) => onError(err));
    }

    if (isClient()) {
      props.fetchLatestReport((err) => onError(err));
      // props.fetchIssueList('dashboard', '', (err) => onError(err));
      props.fetchClientAdmin((err) => onError(err));
      props.fetchEBlast(onError);
    }
    if (!isClient()) props.fetchAdminJunctureList((err) => onError(err));

    if (isSuperAdmin()) {
      let prevFilter =
        Object.keys(existingIssuesData).length > 0 ? existingIssuesData : {};
      if (prevFilter?.filterId) {
        setFilterDropdownValue(prevFilter?.filterId || '');
      }
      setFilter(prevFilter?.filter);

      let data = {};
      if (Object.keys(prevFilter).length > 0) {
        data = { filter: { ...prevFilter?.filter } };
      }

      if (
        data?.filter &&
        Object.keys(data?.filter)?.length &&
        data?.filter?.dateRange !== 'All Time'
      ) {
        let dateRange = getDateRangeValue(data?.filter?.dateRange);
        let filter = {
          ...data?.filter,
          startDate: moment(dateRange.startDate).format('YYYY-MM-DD'),
          endDate: moment(dateRange.endDate).format('YYYY-MM-DD'),
        };
        data = { filter: { ...filter } };
      }
      props.fetchUserListByType('client', (err) => onError(err));
      getSuperAdminIssueList(data);
      props.fetchClientFeedbackList((err) => onError(err));
    }
  }, []);

  const getSuperAdminIssueList = (data) => {
    if (!data.sortBy) data.sortBy = { dueDate: dueSortType === 'asc' ? 1 : -1 };
    if (data?.filter && !Object.keys(data?.filter)?.length) delete data?.filter;
    props.fetchSuperAdminDashboardIssueList(data, (err) => onError(err));
  };

  const setFilterData = (data) => {
    setSelectedClient('all');
    setLocaleStorageVal(issueDataStore, {
      ...existingIssuesData,
      filter: data,
    });
    if (JSON.stringify(data) === '{}' && filter) {
      setFilter(null);
      const filterData = {};
      setFilterDropdownValue('');
      if (isSuperAdmin()) {
        getSuperAdminIssueList(filterData);
      }

      if (isAdmin()) {
        const data = {
          criteria: activeIssueTab,
          sortBy: { dueDate: dueSortType === 'asc' ? 1 : -1 },
          // status: selectedIssueStatus === 'all' ? '' : selectedIssueStatus,
        };
        props.fetchDashboardIssueList(data, (err) => onError(err));
      }
    } else if (JSON.stringify(data) !== '{}') {
      setFilter(data);
      const filterData = {};
      filterData.filter = data;
      setFilterDropdownValue('');
      if (isSuperAdmin()) {
        getSuperAdminIssueList(filterData);
      }
      if (isAdmin()) {
        const adminFilter = {
          criteria: activeIssueTab,
          sortBy: { dueDate: dueSortType === 'asc' ? 1 : -1 },
          // status: selectedIssueStatus === 'all' ? '' : selectedIssueStatus,
        };

        if (data) {
          adminFilter.filter = data;
        }
        props.fetchDashboardIssueList(adminFilter, (err) => onError(err));
      }
    }
  };

  useEffect(() => {
    if (isAdmin()) {
      let data = {
        criteria: activeIssueTab,
        // status: selectedIssueStatus === 'all' ? '' : selectedIssueStatus,
      };
      let prevFilter = existingIssuesData ?? {};
      if (prevFilter?.filterId) {
        setFilterDropdownValue(prevFilter?.filterId || '');
      }
      if (Object.keys(prevFilter).length > 0) {
        data = { ...data, filter: { ...prevFilter?.filter } };
      }
      setFilter(prevFilter?.filter);
      if (
        data?.filter &&
        Object.keys(data?.filter)?.length &&
        data?.filter?.dateRange !== 'All Time'
      ) {
        let dateRange = getDateRangeValue(data?.filter?.dateRange);
        let filter = {
          ...data?.filter,
          startDate: moment(dateRange.startDate).format('YYYY-MM-DD'),
          endDate: moment(dateRange.endDate).format('YYYY-MM-DD'),
        };
        data = {
          ...data,
          filter: { ...filter },
          sortBy: { dueDate: dueSortType === 'asc' ? 1 : -1 },
        };
        if (data?.filter && !Object.keys(data?.filter)?.length)
          delete data.filter;
      }
      props.fetchDashboardIssueList(data, (err) => onError(err));
    }
  }, [activeIssueTab, selectedIssueStatus, props.updateIssueData, dueSortType]);

  useEffect(() => {
    props.fetchAnnouncementList('dashboard', (err) => onError(err));
  }, [props.addAnnouncementData]);

  useEffect(() => {
    const newData = props.dashboardCountData || {};
    if (newData) {
      setDashboardCount(newData);
      setQuillValue(newData?.notes || ``);
    }
  }, [props.dashboardCountData]);

  useEffect(() => {
    if (isClient()) {
      props.fetchJuncturesList((err) => onError(err));
    }

    if (isAdminsDashboardOnly) {
      props.fetchAdminJunctureList((err) => onError(err));
      setTimeout(() => {
        setIsAdminsDashboardOnly(false);
      }, 1000);
    }
  }, [props.addJunctureData, props.updateJunctureData]);

  useEffect(() => {
    if (!isClient()) {
      props.fetchClientsAvailability(activeTab, (err) => onError(err));
    }
  }, [activeTab]);

  useEffect(() => {
    setAnnouncementList(props.announcementListData?.announcements || []);
  }, [props.announcementListData]);

  useEffect(() => {
    if (isAdmin()) {
      const data = props.suppliesNeededData ? props.suppliesNeededData : [];
      let finalData = data.map((item) => {
        let newItem = {
          issueTitle: item.title,
          dueDate: item.dueDate,
          description: item.suppliesNeeded,
          property: item?.property,
          _id: item._id,
        };
        return newItem;
      });
      setSuppliesNeeded(finalData);
    }
  }, [props.suppliesNeededData]);

  useEffect(() => {
    if (isAdmin()) {
      const data = props.teamDiscussionData ? props.teamDiscussionData : [];
      let finalData = data.map((item) => {
        let newItem = {
          issueTitle: item.issueTitle,
          dueDate: item.dueDate,
          description: item.description,
          _id: item._id,
        };
        return newItem;
      });
      setTeamDiscussion(finalData);
    }
  }, [props.teamDiscussionData]);

  useEffect(() => {
    if (isClient()) {
      const data = props.juncturesListData ? props.juncturesListData : [];
      const reverseData = [...data].reverse();
      setJuncturesList(reverseData);
    }
  }, [props.juncturesListData]);

  useEffect(() => {
    const data = props.latestReportData ? props.latestReportData : [];
    setLatestReportList(data);
  }, [props.latestReportData]);

  useEffect(() => {
    const data = props.clientAdminData ? props.clientAdminData : {};
    setLocaleStorageVal('clientAdminEmail', data?.email);
    setClientAdminData(data);
  }, [props.clientAdminData]);

  useEffect(() => {
    const data = props.adminJuncturesListData
      ? props.adminJuncturesListData
      : [];
    setAdminJunctureList(data);
  }, [props.adminJuncturesListData]);

  useEffect(() => {
    if (isSuperAdmin()) {
      const data = props.clientFeedbackListData
        ? props.clientFeedbackListData
        : [];
      setClientFeedbackList(data);
    }
  }, [props.clientFeedbackListData]);

  useEffect(() => {
    if (isSuperAdmin()) {
      const data = props.UserListByTypeData ? props.UserListByTypeData : {};
      let newData =
        data?.data?.map((item) => {
          const tempData = {
            title: item.name,
            value: item._id,
          };
          return tempData;
        }) || [];
      if (data?.type === 'client') setClientList(newData);
    }
  }, [props.UserListByTypeData]);

  const nextItem = () => {
    setCount(count + 1);
    if (count >= announcementList.length - 1) setCount(0);
  };
  const previousItem = () => {
    if (count <= 0) setCount(announcementList.length - 1);
    if (count > 0) setCount(count - 1);
  };

  useEffect(() => {
    (isSuperAdmin() || isAdmin()) &&
      !isFilterChange &&
      props.listFilter(
        isSuperAdmin() ? 'ISSUE_SUPERADMIN_DASHOARD' : 'ISSUE ',
        onError
      );
  }, []);

  useEffect(() => {
    if ((isSuperAdmin() || isAdmin()) && isFilterChange) {
      let prevFilter = existingIssuesData ?? {};
      if (prevFilter?.filterId) {
        setLocaleStorageVal(issueDataStore, {
          sortBy: { key: 'dueDate', value: dueSortType === 'asc' ? 1 : -1 },
        });
      }
      props.listFilter(
        isSuperAdmin() ? 'ISSUE_SUPERADMIN_DASHOARD' : 'ISSUE ',
        onError
      );
      filterDropdownValue && getSuperAdminIssueList({});
      setFilter(null);
      setFilterDropdownValue('');
      setIsFilterChange(false);
    }
  }, [isFilterChange]);

  useEffect(() => {
    const data = props?.listFilterData?.map((el) => ({
      title: el?.filterName,
      value: el?._id,
      filter: el.filter,
    }));
    setFilterDropdownList(data || []);
  }, [props.listFilterData]);

  const onClickRefresh = () => {
    let filterData = {};
    if (filter) filterData.filter = filter;

    if (filterData?.filter && filterData?.filter?.dateRange !== 'All Time') {
      let dateRange = getDateRangeValue(filterData?.filter?.dateRange);
      let filter = {
        ...filterData?.filter,
        startDate: moment(dateRange.startDate).format('YYYY-MM-DD'),
        endDate: moment(dateRange.endDate).format('YYYY-MM-DD'),
      };
      filterData = { filter: { ...filter } };
    }

    if (isSuperAdmin()) {
      setSelectedClient('all');
      getSuperAdminIssueList(
        filterData?.filter ? { filter: filterData?.filter } : {}
      );
    }

    if (isAdmin()) {
      const adminFilter = {
        criteria: activeIssueTab,
        // status: selectedIssueStatus === 'all' ? '' : selectedIssueStatus,
      };
      if (filterData?.filter) {
        adminFilter.filter = filterData?.filter;
        adminFilter.sortBy = { dueDate: dueSortType === 'asc' ? 1 : -1 };
      }
      if (adminFilter?.filter && !Object.keys(adminFilter?.filter)?.length)
        delete adminFilter.filter;
      props.fetchDashboardIssueList(adminFilter, (err) => onError(err));
    }
  };

  const onClickClearFilter = async () => {
    await setSelectedClient('all');
    await setFilterDropdownValue('');
    setLocaleStorageVal(issueDataStore, {
      sortBy: { key: 'dueDate', value: dueSortType === 'asc' ? 1 : -1 },
    });
    // await localStorage.removeItem(issueDataStore);
    await setFilter(null);
    if (isSuperAdmin()) {
      await getSuperAdminIssueList({});
    }
    if (isAdmin()) {
      const data = {
        criteria: activeIssueTab,
        sortBy: { dueDate: dueSortType === 'asc' ? 1 : -1 },
        // status: selectedIssueStatus === 'all' ? '' : selectedIssueStatus,
      };
      props.fetchDashboardIssueList(data, (err) => onError(err));
    }
  };

  const onSuccessDeleteJuncture = async () => {
    await props.deleteJuncture(
      selectedJunctureId,
      () => {
        setShowDeleteJunctureModal(false);
        onSuccess('SuccessFully Deleted');
        if (isClient()) props.fetchJuncturesList((err) => onError(err));
        else props.fetchAdminJunctureList((err) => onError(err));
      },
      onError
    );
  };

  useEffect(() => {
    if (isClient() && Object.keys(props.getEBlastData)?.length > 0) {
      const { buttonText, url } = props.getEBlastData;
      setEBlastButtonText(buttonText || Strings.VIEW_LATEST_EBLAST);
      setEBlastUrl(url || '');
    }
  }, [props.getEBlastData]);

  useEffect(() => {
    if (isSuperAdmin() && props?.updateIssueData) {
      onClickClearFilter();
      // getSuperAdminIssueList({});
    }
  }, [props.updateIssueData]);

  //====== arrival/ departure sorting ================
  function sortingArrDeptHandler(type, data) {
    const copyData = structuredClone(adminJunctureList);
    let sortedData = copyData.sort((a, b) => {
      if (moment(a[type]) < moment(b[type]))
        return sortOrder === 'asc' ? -1 : 1;
      if (moment(a[type]) > moment(b[type]))
        return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });
    setAdminJunctureList(sortedData || []);
  }

  const delayedChangeNote = useCallback(
    debounce((data) => {
      props.addMyNote(data, onError);
    }, 1000),
    []
  );

  const onChangeNote = async (value) => {
    setQuillValue(value?.replace('<p><br></p>', ''));
    const data = { notes: value?.replace('<p><br></p>', '') };
    delayedChangeNote(data);
  };

  const downloadXlsx = (res, fileName) => {
    const url = window.URL.createObjectURL(
      new Blob([res], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  // clear feedback
  const clearFeedback = () => {
    props.clearAllClientFeedback(onError, () => {
      setOpenClearFeedbackModal(false);
      props.fetchClientFeedbackList((err) => onError(err));
    });
  };

  useEffect(() => {
    if (quillValue) manageNotesHref('dashboardNote');
  }, [quillValue]);

  const handleSortClick = () => {
    let type = dueSortType === 'asc' ? 'desc' : 'asc';
    let data = {
      ...existingIssuesData,
      sortBy: { dueDate: type === 'asc' ? 1 : -1 },
    };
    setDueSortType(type);
    setLocaleStorageVal(issueDataStore, {
      ...existingIssuesData,
      sortBy: { key: 'dueDate', value: type === 'asc' ? 1 : -1 },
    });
    if (isSuperAdmin()) getSuperAdminIssueList(data);
  };

  // === clientAvailability data sorting ===
  const clientAvailabilitySort = useCallback(
    (data) => {
      if (data && data?.length) {
        let newData = structuredClone(data);
        newData = newData.sort((next, prev) => {
          let prevLower = prev?.property?.toLowerCase();
          let nextLower = next?.property?.toLowerCase();

          if (!prevLower) return -1;
          if (!nextLower) return 1;
          if (prevLower > nextLower) return -1;
          if (nextLower < prevLower) return 1;
          return 0;
        });
        return newData;
      }
    },
    [clientAvailabilityData]
  );

  return (
    <div className={classes.dashboard_container}>
      <TitleContainer
        // isSearch
        title={`${Strings.WELCOME_BACK} ${userName}`}
        containerClassName={classes.title_container}
      />
      <div className={classes.dashboard_left_container}>
        {!isClient() && (
          <div className={classes.gridRowView}>
            <div
              className={classes.gridView}
              style={{
                gridTemplateColumns: isAdmin() ? 'none' : 'repeat(2, 1fr)',
              }}
            >
              <AnnouncementView
                classes={classes}
                onNextClick={nextItem}
                onPreviousClick={previousItem}
                title={
                  (announcementList &&
                    announcementList?.length > 0 &&
                    announcementList[count]?.title) ||
                  ''
                }
                message={
                  (announcementList &&
                    announcementList?.length > 0 &&
                    announcementList[count].message) ||
                  ''
                }
                isData={
                  announcementList?.length > 0 && !props.announcementListLoading
                    ? true
                    : false
                }
                boxContainer={classes.adminAnnouncement}
                loader={props.announcementListLoading}
                onClickAllAnnouncement={() =>
                  navigate('/app/dashboard/announcements')
                }
              />
              {isSuperAdmin() && (
                <div
                  className={classes.gridRowView}
                  style={{ gridTemplateRows: 'repeat(2, min-content)' }}
                >
                  <Button
                    text={Strings.ALL_ANNOUNCEMENTS}
                    className={classes.primary_button}
                    onClick={() => navigate('/app/dashboard/announcements')}
                  />
                  <Button
                    className={classes.primary_button}
                    text={Strings.MANAGE_EBLAST}
                    onClick={() => setOpenEBlastModal(true)}
                  />
                </div>
              )}
            </div>
            <div style={{ display: 'grid', overflow: 'auto' }}>
              <div className={classes.cards_inner_container}>
                <CommonProgressCard
                  isViewList
                  backgroundColor={isClient() && '#1B2A79'}
                  progressData={
                    isSuperAdmin()
                      ? dashboardCount?.Arrival_In_7_days || 0
                      : isClient()
                      ? dashboardCount.Urgent_Items_for_review || 0
                      : isAdmin()
                      ? dashboardCount.readyForAdminReview || 0
                      : 0
                  }
                  subTitleText={
                    isClient()
                      ? 'Urgent Items for review'
                      : isAdmin()
                      ? 'Reports Ready For Review'
                      : 'Arrivals in the next 7 days'
                  }
                  onViewClick={async () => {
                    if (isSuperAdmin()) {
                      navigate('/app/jobs');
                    } else if (isClient()) {
                      navigate('/app/issues');
                    } else if (isAdmin()) {
                      let data = {
                        jobStatus: ['Ready for Admin Review'],
                        dateRange: 'All Time',
                        showAllJob: false,
                      };
                      await setLocaleStorageVal('JobsData', { filter: data });
                      await navigate('/app/jobs');
                    }
                  }}
                  navigationText={
                    !isClient() ? Strings.VIEW_LIST : Strings.VIEW_ALL
                  }
                  // showDataPercentage={isSuperAdmin() ? true : false}
                  // percentageValue={53}
                />
                <CommonProgressCard
                  isViewList
                  backgroundColor={isClient() ? '#0F1B5F' : '#1B2A79'}
                  progressData={
                    isSuperAdmin()
                      ? dashboardCount?.Issues_marked_critical || 0
                      : isClient()
                      ? dashboardCount.Unopened_reports || 0
                      : isAdmin()
                      ? dashboardCount.issue_teamDicussionNeeded || 0
                      : 0
                  }
                  subTitleText={
                    isClient()
                      ? 'Unopened reports'
                      : isAdmin()
                      ? 'Tasks - Team Discussion Needed'
                      : 'Tasks marked critical'
                  }
                  onViewClick={async () => {
                    if (isAdmin()) {
                      let data = {
                        status: ['Team Discussion Needed'],
                        dateRange: 'All Time',
                        showAllIssue: false,
                      };
                      await setLocaleStorageVal('IssuesData', { filter: data });
                      await navigate('/app/issues');
                    } else if (isSuperAdmin()) {
                      let data = {
                        priority: ['Critical'],
                        dateRange: 'All Time',
                      };
                      await setLocaleStorageVal('IssuesData', { filter: data });
                      await navigate('/app/issues');
                    } else {
                      navigate('/app/reports');
                    }
                  }}
                  navigationText={
                    !isClient() ? Strings.VIEW_LIST : Strings.VIEW_DETAILS
                  }
                />
                <CommonProgressCard
                  isViewList={!isClient() ? true : false}
                  backgroundColor={!isClient() && '#0F1B5F'}
                  progressData={
                    isSuperAdmin()
                      ? dashboardCount?.Disscuss_With_Steph_And_Dave
                      : isAdmin()
                      ? dashboardCount?.job_teamDicussionNeeded
                      : 0
                  }
                  subTitleText={
                    isClient()
                      ? 'Next scheduled visit'
                      : isAdmin()
                      ? `Jobs - Team Discussion Needed`
                      : 'Jobs - Team Discussion Needed'
                  }
                  isDate={isClient() ? true : false}
                  date={
                    (isClient() &&
                      dashboardCount?.Next_scheduled_visit?.startDate) ||
                    new Date()
                  }
                  onViewClick={async () => {
                    if (isClient()) {
                      await navigate('/app/jobs');
                    } else {
                      let data = {
                        jobStatus: ['Team Discussion Needed'],
                        dateRange: 'All Time',
                      };
                      if (isAdmin()) data.showAllJob = false;
                      await setLocaleStorageVal('JobsData', { filter: data });
                      await navigate('/app/jobs');
                    }
                  }}
                  navigationText={!isClient() ? Strings.VIEW_LIST : null}
                />
              </div>
            </div>
            <div className={classes.gridView}>
              {!isClient() && (
                <div
                  className={classNames(
                    classes.adminGridRowView,
                    classes.admin_content_container
                  )}
                >
                  {/*==== upcoming arrival departure ==== */}
                  <CommonBoxView
                    listTitle={Strings.UPCOMING_ARRIVALS_DEPARTURE}
                    handleTitleClick={() =>
                      navigate('/app/dashboard/arrivals-departures')
                    }
                    isArrivalIcon={
                      !props.adminJuncturesListLoading &&
                      adminJunctureList?.length > 0
                    }
                    onSortArrivalClick={() => {
                      sortingArrDeptHandler('arrival');
                      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
                    }}
                    isDepartureIcon={
                      !props.adminJuncturesListLoading &&
                      adminJunctureList?.length > 0
                    }
                    onSortDepttureClick={() => {
                      sortingArrDeptHandler('departure');
                      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
                    }}
                    isDownloadIcon={
                      !props.adminJuncturesListLoading &&
                      adminJunctureList?.length > 0
                    }
                    onDownloadClick={() => {
                      props.exportJunctures(
                        async (res) => {
                          await downloadXlsx(res, 'Arrival-Junctures');
                        },
                        (err) => onError(err)
                      );
                    }}
                    isAddIcon={!props.adminJuncturesListLoading}
                    onAddClick={() => {
                      setShowArrivalDialog(true);
                      setSelectedArrivalDeparture({});
                      setIsAdminsDashboardOnly(true);
                    }}
                  >
                    {props.adminJuncturesListLoading ? (
                      <CommonLoader />
                    ) : adminJunctureList?.length === 0 ? (
                      <CommonNoDataFound />
                    ) : (
                      <UpcomingArrivalListView
                        dataList={adminJunctureList}
                        theme={theme}
                        setShowDeleteJunctureModal={setShowDeleteJunctureModal}
                        setSelectedJunctureId={setSelectedJunctureId}
                        setIsEdit={setIsEdit}
                        setShowArrivalDialog={setShowArrivalDialog}
                        setSelectedArrivalDeparture={
                          setSelectedArrivalDeparture
                        }
                        setIsAdminsDashboardOnly={setIsAdminsDashboardOnly}
                      />
                    )}
                  </CommonBoxView>
                  <CommonBoxView
                    isDate
                    isTabView
                    date={moment(new Date()).format('MMM DD, YYYY')}
                    tabArray={tabsArray}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  >
                    <PropertyContainer
                      loading={props.clientsAvailabilityLoading}
                      data={clientAvailabilitySort(
                        clientAvailabilityData?.junctureList
                      )}
                    />
                  </CommonBoxView>
                </div>
              )}
              {!isClient() && (
                <div
                  className={classNames(
                    classes.adminGridRowView,
                    classes.admin_content_container
                  )}
                >
                  <div
                    className={classes.issue_container}
                    style={{ marginBottom: 15, height: '100%' }}
                  >
                    <div className={classes.quillHeader}>
                      <TableToolbox
                        isLabel
                        labelText={Strings.MY_NOTES}
                        labelClassName={classes.toolbox_label}
                      />

                      {!props.dashboardCountLoading && (
                        <label
                          className={classes.clear_txt}
                          onClick={() =>
                            quillValue && setOpenClearNoteDialog(true)
                          }
                        >
                          {'Clear'}
                        </label>
                      )}
                    </div>
                    {props.dashboardCountLoading ? (
                      <CommonLoader style={{ width: '100%', marginTop: 15 }} />
                    ) : (
                      <ReactQuill
                        id='dashboardNote'
                        theme={quillData.theme}
                        onChange={(value) => onChangeNote(value)}
                        value={quillValue}
                        modules={EditorModules}
                        formats={EditorFormats}
                        placeholder={'Type here'}
                        style={{ width: '100%', marginTop: 10, height: '76%' }}
                      />
                    )}
                  </div>

                  {isSuperAdmin() ? (
                    <div
                      className={classNames(
                        classes.gridColumnView,
                        classes.upcoming_list_container
                      )}
                    >
                      <CommonBoxView
                        listTitle={`${Strings.FEEDBACK_FROM_THE_CLIENT} (${
                          clientFeedbackList.length || 0
                        })`}
                        headerChildren={
                          clientFeedbackList.length ? (
                            <label
                              className={classes.clear_txt}
                              onClick={() => setOpenClearFeedbackModal(true)}
                              style={{ marginTop: 5 }}
                            >
                              {Strings.CLEAR_ALL}
                            </label>
                          ) : null
                        }
                      >
                        <div styles={{ marginTop: 10 }}>
                          <FeedBackContainer
                            dataList={clientFeedbackList || []}
                            loading={props.clientFeedbackListLoading}
                          />
                        </div>
                      </CommonBoxView>
                    </div>
                  ) : null}

                  {/* <CommonBoxView listTitle={Strings.TEAM_DISCUSSION_NEEDED}>
                    <IssuesContainer
                      data={teamDiscussion}
                      noCollapse
                      loading={props.teamDiscussionLoading}
                      noDataString={'None for today'}
                    />
                  </CommonBoxView> */}
                  {isAdmin() ? (
                    <CommonBoxView
                      listTitle={Strings.SUPPLIES_NEEDED_FOR_DAY}
                      // isPrintIcon
                      isDownloadIcon
                      onDownloadClick={() => {
                        props.downloadSupplies(async (res) => {
                          await downloadXlsx(
                            res,
                            'Supplies_needed_' + moment().format('MMM DD, YYYY')
                          );
                        }, onError);
                      }}
                    >
                      <IssuesContainer
                        data={suppliesNeeded}
                        noCollapse
                        loading={props.suppliesNeededLoading}
                        noDataString={'None for today'}
                        isSuppliesNeeded={true}
                      />
                    </CommonBoxView>
                  ) : null}
                </div>
              )}
            </div>
          </div>
        )}
        {isClient() && (
          <div className={classes.gridRowView}>
            <div className={classes.gridView}>
              <div
                className={classes.gridRowView}
                style={{
                  gridTemplateRows: 'repeat(3, min-content)',
                  alignContent: 'space-between',
                }}
              >
                <Button
                  className={classes.primary_button}
                  text={Strings.EMAIL_MY_ADMIN}
                  onClick={() => {
                    window.location.href = `mailto:${
                      clientAdminData?.email || ''
                    }`;
                  }}
                />
                <Button
                  className={classNames(classes.primary_button)}
                  text={Strings.SEND_MANAGEMENT_FEEDBACK}
                  onClick={() => {
                    setShowSendManagementFeedbackDialog(true);
                  }}
                />
                <Button
                  className={classNames(classes.primary_button)}
                  text={eBlastButtonText || Strings.VIEW_LATEST_EBLAST}
                  onClick={() => eBlastUrl && window.open(eBlastUrl)}
                  loading={props.getEBlastLoading}
                  loaderColor={theme.palette.background_button_text}
                />
              </div>
              <div className={classes.gridRowView}>
                <CommonProgressCard
                  isDate
                  showCountOneLine
                  onCardClick
                  backgroundColor={dashboardCount.Urgent_Items_for_review > 0 ? Colors.RED : '#0F1B5F'}
                  progressData={dashboardCount.Urgent_Items_for_review || 0}
                  subTitleText='Items Requiring Your Input'
                  onViewClick={async () => {
                    let data = {
                      status: [`Requested Client's Input`],
                      showAllIssue: false,
                    };
                    await setLocaleStorageVal('IssuesData', { filter: data });
                    navigate('/app/issues');
                  }}
                  navigationText={Strings.VIEW_ALL}
                />
                <CommonProgressCard
                  isDate
                  showCountOneLine
                  onCardClick
                  backgroundColor={'#0F1B5F'}
                  progressData={dashboardCount.Unopened_reports || 0}
                  subTitleText={'Unopened reports'}
                  onViewClick={() => navigate('/app/reports')}
                  navigationText={Strings.VIEW_DETAILS}
                />
              </div>
            </div>
            <div className={classes.gridView}>
              <div className={classes.client_profile_box}>
                <div className={classes.client_profile_boxContainer}>
                  {props.clientAdminLoading ? (
                    <CommonLoader style={{ width: '100%' }} />
                  ) : !clientAdminData ||
                    Object.keys(clientAdminData)?.length === 0 ? (
                    <CommonNoDataFound />
                  ) : (
                    <>
                      <span className={classes.report_title_text}>
                        {Strings.YOUR_DEDICATED_ADMIN}
                      </span>
                      <div className={classes.client_profile_content}>
                        <a
                          href={`${clientAdminData?.bioLink || '#'}`}
                          target={clientAdminData?.bioLink && '_blank'}
                        >
                          <Avatar
                            src={clientAdminData?.profilePicture}
                            alt=''
                            className={classes.avatar_icon}
                          />
                        </a>
                        <div className={classes.flex_column_view}>
                          <label
                            className={classes.common_header_text}
                            style={{ marginBottom: '10px' }}
                          >
                            {clientAdminData?.name}
                          </label>
                          <CommonProfileLabel
                            title={Strings.OFFICE}
                            titleValue='239-777-6173'
                            href={`tel:2397776173`}
                          />

                          <CommonProfileLabel
                            title={Strings.CELL}
                            titleValue={formatingPhoneNumber(
                              clientAdminData?.phoneNumber,
                              true
                            )}
                            href={`tel:${clientAdminData?.phoneNumber}`}
                          />
                          <CommonProfileLabel
                            title={Strings.EMAIL}
                            titleValue={clientAdminData?.email}
                            href={`mailto:${clientAdminData?.email}`}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <AnnouncementView
                classes={classes}
                onNextClick={nextItem}
                onPreviousClick={previousItem}
                title={
                  (announcementList &&
                    announcementList?.length > 0 &&
                    announcementList[count]?.title) ||
                  ''
                }
                message={
                  (announcementList &&
                    announcementList?.length > 0 &&
                    announcementList[count].message) ||
                  ''
                }
                isData={
                  announcementList?.length > 0 && !props.announcementListLoading
                    ? true
                    : false
                }
                loader={props.announcementListLoading}
                boxContainer={classes.clientAnnouncement}
              />
            </div>
            <div className={classes.gridView}>
              <CommonBoxView
                listTitle='Latest reports'
                containerClassName={classes.latest_report_container}
              >
                {props.latestReportLoading ? (
                  <CommonLoader />
                ) : latestReportList?.length === 0 ? (
                  <CommonNoDataFound />
                ) : (
                  latestReportList?.map((data, index) => (
                    <LatestReportBox
                      key={index}
                      title={data?.auditTitle}
                      userName={data?.createdBy?.name}
                      date={moment(data?.createdAt).format('MMM DD, YYYY')}
                      onViewClick={() =>
                        navigate('/app/report', {
                          state: {
                            jobId: data?.job,
                          },
                        })
                      }
                    />
                  ))
                )}
              </CommonBoxView>
              <WeatherWidget
                weatherData={props.getForecastWeatherData}
                currentWeatherData={props.getCurrentWeatherData}
                loading={
                  fetchLatLngLoader ||
                  props.getForecastWeatherLoading ||
                  props.getCurrentWeatherLoading
                }
                coords={coords}
                grantPermission={grantPermission}
                notificationType={notificationType}
              />
            </div>
          </div>
        )}
      </div>
      <div className={classes.dashboard_right_container}>
        {isClient() && (
          <div className={classes.issue_container}>
            {dashboardCount?.properties?.length > 0 && (
              <Swiper
                spaceBetween={10}
                pagination={{
                  dynamicBullets: true,
                  clickable: true,
                }}
                navigation={true}
                modules={[Navigation, Pagination]}
                className='mySwiper'
              >
                {dashboardCount?.properties?.map((item, i) => (
                  <SwiperSlide key={item._id}>
                    <img
                      className={classes.property_image}
                      src={item?.image?.url}
                      alt={Strings.PROPERTY}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
            <div
              className={classes.issues_list_view}
              style={{ overflow: 'hidden' }}
            >
              <div className={classes.arrival_departure_container}>
                <span className={classes.toolbox_label}>
                  {Strings.YOUR_ARRIVALS_DEPARTURE}
                </span>
                <div>
                  <span
                    className={classes.text_blue}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setShowArrivalDialog(true);
                      setSelectedArrivalDeparture({});
                    }}
                  >
                    {Strings.ADD_NEW}
                  </span>
                </div>
                {props.juncturesListLoading ? (
                  <CommonLoader />
                ) : juncturesList?.length === 0 ? (
                  <CommonNoDataFound />
                ) : (
                  <div className={classes.arrival_departure_list_container}>
                    {juncturesList?.map((item, index) => (
                      <div key={index} className={classes.time_view_container}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <label
                            htmlFor='property'
                            className={classes.common_text}
                            style={{ flex: 1 }}
                          >
                            {item?.property?.name}
                          </label>
                          <img
                            src={icons(theme).editIcon}
                            alt=''
                            className={classes.icons}
                            onClick={() => {
                              setIsEdit(true);
                              setShowArrivalDialog(true);
                              setSelectedArrivalDeparture(item);
                            }}
                          />
                          <img
                            src={icons(theme).deleteIcon}
                            alt=''
                            className={classes.icons}
                            onClick={() => {
                              setShowDeleteJunctureModal(true);
                              setSelectedJunctureId(item._id);
                            }}
                          />
                        </div>
                        <ArrivalDepartureView
                          classes={classes}
                          dateTime={item?.arrival}
                          action={Strings.ARRIVAL}
                          arrivalNote={item?.arrivalNotes}
                        />
                        <ArrivalDepartureView
                          classes={classes}
                          dateTime={item?.departure}
                          action={Strings.DEPARTURE}
                        />
                        <Stack gap={1.25}>
                          {item?.arrivalNotes ? (
                            <Stack gap={0.5}>
                              <Typography
                                lineHeight='normal'
                                className={classes.text_bold}
                              >
                                {Strings.ARRIVAL_NOTES}
                              </Typography>
                              <Typography className={classes.arrivalSubText}>
                                {item?.arrivalNotes}
                              </Typography>
                            </Stack>
                          ) : null}
                          {item?.departureNotes ? (
                            <Stack gap={0.5}>
                              <Typography
                                lineHeight='normal'
                                className={classes.text_bold}
                              >
                                {Strings.DEPARTURE_NOTES}
                              </Typography>
                              <Typography className={classes.arrivalSubText}>
                                {item?.departureNotes}
                              </Typography>
                            </Stack>
                          ) : null}
                        </Stack>
                        {juncturesList?.length !== index + 1 ? (
                          <Divider
                            sx={{
                              borderColor: theme.palette.background_border,
                            }}
                          />
                        ) : null}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {!isClient() && (
          <div className={classes.issue_container}>
            <TableToolbox
              isLabel
              isFilter={isSuperAdmin() || isAdmin()}
              isSort={true}
              isViewAll
              isSortAsc={dueSortType === 'asc' || false}
              sortTitle={`${
                dueSortType === 'asc' ? Strings.ASCENDING : Strings.DESCENDING
              } Due Date`}
              labelText={Strings.ISSUES}
              onViewAllClick={() => navigate('/app/issues')}
              labelClassName={classes.toolbox_label}
              filterButtonClick={() => setOpenIssuesFilter(true)}
              onClickRefresh={() => onClickRefresh()}
              onClickClearFilter={() => onClickClearFilter()}
              onSortClick={handleSortClick}
            />
            {isAdmin() && (
              <Tabs
                dataList={issuesTabArray}
                activeTab={activeIssueTab}
                onChange={(value) => setActiveIssueTab(value)}
                containerClassName={classes.issue_tab_container}
                tabClassName={classes.issue_tab_content}
              />
            )}
            {/* <Dropdown
              name='array'
              onChange={(e) => {
                {
                  if (isAdmin()) setSelectedIssueStatus(e.target.value);
                  else if (isSuperAdmin()) {
                    setFilter(null);
                    setSelectedClient(e.target.value);
                    const data = {};
                    if (e.target.value !== 'all') data.client = e.target.value;
                    getSuperAdminIssueList(data);
                    setFilterDropdownValue('0');
                  }
                }
              }}
              value={isAdmin() ? selectedIssueStatus : selectedClient}
              labelClassName={classes.form_label}
              inputContainerClass={classes.selector}
              containerClassName={classes.form_input_container}
              optionArray={
                isAdmin()
                  ? [{ title: 'All status', value: 'all' }, ...issueStatusArray]
                  : isSuperAdmin() && [
                      { title: 'All clients', value: 'all' },
                      ...clientList,
                    ]
              }
            /> */}
            {(isSuperAdmin() || isAdmin()) &&
              filterDropdownList?.length > 0 && (
                <Dropdown
                  onChange={(e) => {
                    setFilterDropdownValue(e);

                    let filterData = filterDropdownList?.filter(
                      (el) => el.value === e
                    )[0];
                    if (filterData?.filter) setFilter(filterData?.filter);

                    if (
                      filterData?.filter &&
                      filterData?.filter?.dateRange !== 'All Time'
                    ) {
                      let dateRange = getDateRangeValue(
                        filterData?.filter?.dateRange
                      );
                      let filter = {
                        ...filterData?.filter,
                        startDate: moment(dateRange.startDate).format(
                          'YYYY-MM-DD'
                        ),
                        endDate: moment(dateRange.endDate).format('YYYY-MM-DD'),
                      };
                      filterData = { filter: { ...filter } };
                    }
                    setLocaleStorageVal(issueDataStore, {
                      ...existingIssuesData,
                      filter: { ...filterData?.filter },
                      filterId: e,
                    });

                    if (isSuperAdmin()) {
                      setSelectedClient('all');
                      getSuperAdminIssueList(
                        filterData?.filter ? { filter: filterData?.filter } : {}
                      );
                    }

                    if (isAdmin()) {
                      let data = {
                        criteria: activeIssueTab,
                        // status: selectedIssueStatus === 'all' ? '' : selectedIssueStatus,
                        filter: filterData?.filter,
                        sortBy: { dueDate: dueSortType === 'asc' ? 1 : -1 },
                      };

                      props.fetchDashboardIssueList(data, (err) =>
                        onError(err)
                      );
                    }
                  }}
                  value={filterDropdownValue}
                  title={Strings.SELECT_SAVED_FILTER}
                  className={classes.form_input}
                  inputContainerClass={classes.selector}
                  containerClassName={classes.form_input_container}
                  optionArray={filterDropdownList}
                  placeholder={Strings.SELECT_SAVED_FILTER}
                  isEditIcon
                  onEditIconClick={(value) =>
                    setOpenEditFilterDialog({ ...value, show: true })
                  }
                  searchable
                />
              )}
            <div className={classes.issues_list_view}>
              <IssuesContainer
                data={
                  isAdmin()
                    ? issueListData?.issues || []
                    : isClient()
                    ? clientIssueListData || []
                    : isSuperAdmin()
                    ? superAdminIssueListData || []
                    : []
                }
                loading={
                  isAdmin()
                    ? props.dashboardIssueListLoading
                    : isClient()
                    ? props.issueListLoading
                    : isSuperAdmin()
                    ? props.superAdminDashboardIssueListLoading
                    : false
                }
              />
            </div>
          </div>
        )}
      </div>
      {showArrivalDialog && (
        <NewArrivalDeparture
          isEdit={isEdit}
          arrivalDepartureData={selectedArrivalDeparture}
          onNewClose={() => {
            setSelectedArrivalDeparture({});
            setShowArrivalDialog(false);
            setIsEdit(false);
            setTimeout(() => {
              if (!props.adminJuncturesListLoading)
                setIsAdminsDashboardOnly(false);
            }, 100);
          }}
          isAdminsDashboardOnly={isAdminsDashboardOnly}
        />
      )}
      {openIssuesFilter && (isSuperAdmin() || isAdmin()) && (
        <IssuesFilter
          onClose={() => setOpenIssuesFilter(false)}
          setIsFilterChange={setIsFilterChange}
          filterData={filter}
          setFilterData={setFilterData}
          dashboardFilter={isSuperAdmin() ? true : false}
        />
      )}
      {isClient() && showSendManagementFeedbackDialog && (
        <SendManagementFeedback
          onNewClose={() => setShowSendManagementFeedbackDialog(false)}
        />
      )}
      {openEBlastModal && (
        <ManageEBlast onNewClose={() => setOpenEBlastModal(false)} />
      )}
      {(isSuperAdmin() || isAdmin()) && openEditFilterDialog?.show && (
        <IssuesNewFilter
          isEdit={true}
          selectedId={openEditFilterDialog?.value}
          setIsFilterChange={setIsFilterChange}
          onClose={() => setOpenEditFilterDialog()}
          dashboardFilter={isSuperAdmin() ? true : false}
        />
      )}

      {showDeleteJunctureModal && (
        <CommonDialog
          title={Strings.DELETE_ARRIVAL_DEPARTURE}
          message={Strings.ARE_YOU_SURE_DELETE_ARRIVAL_DEPARTURE}
          onSuccess={() => onSuccessDeleteJuncture()}
          onClose={() => {
            setShowDeleteJunctureModal(false);
          }}
          loading={props.deleteJunctureLoading}
        />
      )}

      {openClearNoteDialog && (
        <CommonDialog
          title={Strings.CLEAR_NOTE}
          message={Strings.ARE_YOU_SURE_CLEAR_NOTE}
          onSuccess={() => {
            setQuillValue(``);
            setOpenClearNoteDialog(false);
          }}
          onClose={() => setOpenClearNoteDialog(false)}
          loading={props.deleteJunctureLoading}
        />
      )}
      {openClearFeedbackModal ? (
        <CommonDialog
          title={Strings.ALL_CLIENT_FEEDBACKS}
          message={Strings.ALL_CLIENT_FEEDBACK_NOTE}
          onSuccess={clearFeedback}
          onClose={() => setOpenClearFeedbackModal(false)}
          loading={props.clearAllClientFeedbackLoading}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  addAnnouncementData: state.addAnnouncementData.data,
  announcementListData: state.announcementListData.data,
  announcementListLoading: state.announcementListData.loading,

  juncturesListData: state.juncturesListData.data,
  juncturesListLoading: state.juncturesListData.loading,

  addJunctureData: state.addJunctureData.data,

  updateJunctureData: state.updateJunctureData.data,

  suppliesNeededData: state.suppliesNeededData.data,
  suppliesNeededLoading: state.suppliesNeededData.loading,

  teamDiscussionData: state.teamDiscussionData.data,
  teamDiscussionLoading: state.teamDiscussionData.loading,

  dashboardIssueListData: state.dashboardIssueListData.data,
  dashboardIssueListLoading: state.dashboardIssueListData.loading,

  UserListByTypeData: state.UserListByTypeData.data,
  UserListByTypeLoading: state.UserListByTypeData.loading,

  clientsAvailabilityData: state.clientsAvailabilityListData.data,
  clientsAvailabilityLoading: state.clientsAvailabilityListData.loading,

  latestReportData: state.latestReportData.data,
  latestReportLoading: state.latestReportData.loading,

  issueListData: state.issueListData.data,
  issueListLoading: state.issueListData.loading,

  clientAdminData: state.clientAdminData.data,
  clientAdminLoading: state.clientAdminData.loading,

  dashboardCountData: state.dashboardCountData.data,
  dashboardCountLoading: state.dashboardCountData.loading,

  superAdminDashboardIssueListData: state.superAdminDashboardIssueListData.data,
  superAdminDashboardIssueListLoading:
    state.superAdminDashboardIssueListData.loading,

  adminJuncturesListData: state.adminJuncturesListData.data,
  adminJuncturesListLoading: state.adminJuncturesListData.loading,

  clientFeedbackListData: state.clientFeedbackListData.data,
  clientFeedbackListLoading: state.clientFeedbackListData.loading,

  listFilterData: state.listFilterData.data,
  listFilterLoading: state.listFilterData.loading,

  addFilterData: state.addFilterData.data,
  updateFilterData: state.updateFilterData.data,
  deleteFilterData: state.deleteFilterData.data,

  deleteJunctureData: state.deleteJunctureData.data,
  deleteJunctureLoading: state.deleteJunctureData.loading,

  getEBlastLoading: state.getEBlastData.loading,
  getEBlastData: state.getEBlastData.data,

  getForecastWeatherData: state.getForecastWeatherData.data,
  getForecastWeatherLoading: state.getForecastWeatherData.loading,
  getCurrentWeatherData: state.getCurrentWeatherData.data,
  getCurrentWeatherLoading: state.getCurrentWeatherData.loading,

  updateIssueData: state.updateIssueData.data,

  clearAllClientFeedbackLoading: state.clearAllClientFeedbackData.loading,
  clearAllClientFeedbackData: state.clearAllClientFeedbackData.data,
});

export default connect(mapStateToProps, {
  fetchAnnouncementList,
  fetchJuncturesList,
  fetchSuppliesNeeded,
  fetchTeamDiscussion,
  fetchDashboardIssueList,
  updateJuncture,
  fetchUserListByType,
  fetchClientsAvailability,
  fetchLatestReport,
  fetchIssueList,
  fetchClientAdmin,
  fetchDashboardCount,
  fetchSuperAdminDashboardIssueList,
  fetchAdminJunctureList,
  fetchClientFeedbackList,
  exportJunctures,
  listFilter,
  deleteJuncture,
  fetchEBlast,
  getForecastWeather,
  getCurrentWeather,
  addMyNote,
  downloadSupplies,
  clearAllClientFeedback,
})(Dashboard);
