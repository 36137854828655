import React, { useCallback, useEffect, useRef, useState } from 'react';
import useStyles from './styles';
import { useFormik, Form, FormikProvider } from 'formik';
import { useTheme } from '@mui/material/styles';
import classNames from 'classnames';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Textbox from '../../components/Textbox';
import { Strings } from '../../utils/strings';
import Button from '../../components/Button';
import { Grid, Hidden, Stack, Typography, useMediaQuery } from '@mui/material';
import CustomRadioButton from '../../components/CustomRadioButton';
import CountryCodePicker from '../../components/CountryCodePicker';
import { AddPropertySchema } from '../../utils/validationSchemas';
import { FileDrop } from 'react-file-drop';
import Tabs from '../../components/Tabs';
import { connect, useDispatch } from 'react-redux';
import { createProperty } from '../../redux/actions/clientActions/addProperty';
import {
  downloadFile,
  formatingPhoneNumber,
  getErrorMessage,
  isClient,
  setLocaleStorageVal,
} from '../../utils/appUtils';
import {
  fetchPropertyById,
  setPropertyById,
} from '../../redux/actions/clientActions/getPropertyById';
import { fetchJuncturesListByProperty } from '../../redux/actions/clientActions/juncturesListByProperty';
import moment from 'moment';
import { uploadFile } from '../../redux/actions/adminActions/uploadFile';
import { CircularProgress } from '@material-ui/core';
import { Colors } from '../../utils/color';
import { deleteFile } from '../../redux/actions/adminActions/deleteFile';
import { updateProperty } from '../../redux/actions/clientActions/updateProperty';
// import MoreIcon from '../../assets/images/more-horiz.svg';
import { createNotes } from '../../redux/actions/clientActions/addNotes';
import {
  fetchNotesList,
  setPropertyNotesList,
} from '../../redux/actions/clientActions/notesList';
import IssuesContainer from '../../components/IssuesContainer';
import MediaImageContainer from '../../components/MediaImageContainer';
import { icons } from '../../utils/icons';
import {
  fetchIssuesByProperty,
  setIssuesByProperty,
} from '../../redux/actions/clientActions/getIssuesByProperty';
import {
  fetchJobsByProperty,
  setJobsByProperty,
} from '../../redux/actions/clientActions/getJobsByProperty';
import {
  fetchMediaByProperty,
  setMediaByProperty,
} from '../../redux/actions/clientActions/getMediaByProperty';
import { fetchClientAllProperty } from '../../redux/actions/clientActions/getClientAllProperty';
import JobsContainer from '../../components/jobsContainer';
import { useAlert } from 'react-alert';
import TitleContainer from '../../components/TitleContainer';
import useGoogleMapsApi from '../../hooks/useGoogleMapsApi';
import { fetchVendorDropDownList } from '../../redux/actions/adminActions/vendorDropdownList';
import Dropdown from '../../components/Dropdown';
import CustomizedTooltips from '../../components/CustomizedTooltips';
import NewVendor from '../Vendor/NewVendor';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import NewArrivalDeparture from '../Dashboard/ArrivalDepartureForm';
import { uploadPropertyMedia } from '../../redux/actions/clientActions/uploadPropertyMedia';
import TagsInput from '../../components/TagsInput';
import CommonDialog from '../../components/CommonDialog';
import { deleteJuncture } from '../../redux/actions/clientActions/deleteJuncture';
import ImageViewer from '../../components/ImageViewer';
import ImageCropper from '../../components/ImageCropper';
import MediaDetails from '../Media/MediaDetails';
import { fetchJobList } from '../../redux/actions/jobActions/jobActions/jobList';
import { fetchProgramList } from '../../redux/actions/clientActions/programTypeList';
// import { debounce } from 'lodash';
import { deletePropertyMedia } from '../../redux/actions/clientActions/deletePropertyMedia';
import JobDetails from '../Jobs/JobDetails';
import { debounce } from 'lodash';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const EditorModules = {
  toolbar: [
    // [{ header: '1' }, { header: '2' }, { font: [] }],
    // [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike'], // 'blockquote'
    [
      { list: 'ordered' },
      { list: 'bullet' },
      // { indent: '-1' },
      // { indent: '+1' },
    ],
    // ['link', 'image', 'video'],
    ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
const EditorFormats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
];
const Information = (props) => {
  const {
    title,
    openInformation,
    setOpenInformation,
    dataInformation,
    data: sectionData,
    onChange,
    viewMode,
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [quillData, setQuillData] = useState({ theme: 'snow' });
  const [data, setData] = useState(sectionData);

  const renderTags = (props) => {
    let {
      tag,
      key,
      disabled,
      onRemove,
      classNameRemove,
      getTagDisplayValue,
      ...other
    } = props;
    return (
      <span key={key} {...other}>
        {getTagDisplayValue(tag)}
        {!viewMode && (
          <a className={classNameRemove} onClick={(e) => onRemove(key)} />
        )}
      </span>
    );
  };

  const handleOpenInformation = () => {
    if (openInformation.findIndex((item, index) => item === title) === -1) {
      setOpenInformation([...openInformation, title]);
    } else {
      setOpenInformation(openInformation.filter((item) => item !== title));
    }
  };

  useEffect(() => {
    if (sectionData) setData(sectionData);
  }, [sectionData]);

  const handleChange = (value, fieldName) => {
    if (fieldName) {
      let newData = structuredClone(data);
      if (fieldName === 'generalNotes') newData = value;
      else newData[fieldName] = value;
      setData(newData);
      onChange(value, fieldName);
    }
  };

  return (
    <div className={classes.information_container}>
      <div
        className={classes.label_container}
        onClick={() => handleOpenInformation()}
      >
        <div className={classes.flex_row}>
          <img
            className={classes.toggle_arrow}
            src={
              openInformation.findIndex((item) => item === title) !== -1
                ? icons(theme).downArrow
                : icons(theme).rightArrow
            }
            alt=''
            // onClick={() => handleOpenInformation()}
          />
          <label
            htmlFor='information'
            className={classes.information_title}
            style={{ cursor: 'pointer' }}
          >
            {title}
          </label>
        </div>
        {props.addVendor &&
          !isClient() &&
          openInformation.findIndex((item) => item === title) !== -1 && (
            <CustomizedTooltips title={Strings.NEW_VENDOR}>
              <img
                className={classes.add_vendor}
                src={icons(theme).plusIcon}
                alt=''
                onClick={() => props.setAddNewVendor(true)}
              />
            </CustomizedTooltips>
          )}
      </div>
      {openInformation.findIndex((item) => item === title) !== -1 && (
        <div className={classes.information_data_container}>
          {dataInformation?.map((item, index) => (
            <React.Fragment key={index}>
              {(!viewMode ||
                (viewMode && data[item.label]) ||
                (item.type === 'quill' &&
                  ((item?.label === 'generalNotes' && data) ||
                    data[item.label])) ||
                item.type === 'tagsInput' ||
                item.label === 'alarmExist' ||
                item.label === 'keyRequired' ||
                item.label === 'garbageAccessDoorLocked') && (
                <Grid
                  container
                  spacing={{ xs: 1, sm: 1, md: 2 }}
                  className={classes.data_grid}
                  style={{
                    display:
                      viewMode && data[item.label]?.length === 0 && 'none',
                  }}
                >
                  {item?.title &&
                    (!viewMode
                      ? data || data[item.label]
                      : item.type === 'quill'
                      ? item?.label === 'generalNotes'
                        ? data?.replace('<p><br></p>', '')
                        : data[item.label]?.replace('<p><br></p>', '')
                      : data || data[item.label]) && (
                      <Grid
                        item
                        alignItems={'center'}
                        display={'flex'}
                        xs={12}
                        md={5}
                      >
                        <label className={classes.data_label}>
                          {item.title}
                        </label>
                      </Grid>
                    )}
                  {item.type === 'quill' && !viewMode ? (
                    <Grid
                      item
                      xs={12}
                      md={item?.title ? 7 : 12}
                      style={{
                        width: '100%',
                        paddingRight: item?.label === 'generalNotes' ? 20 : 0,
                        flexDirection: 'column',
                      }}
                    >
                      {/* <div
                        id='toolbar'
                        style={{
                          width: '100%',
                          display: !viewMode ? 'block' : 'none',
                        }}
                      >
                        <button class='ql-bold'></button>
                        <button class='ql-italic'></button>
                        <button class='ql-underline'></button>
                        <button class='ql-strike'></button>
                        <button className='ql-list' value='ordered' />
                        <button className='ql-list' value='bullet' />
                        <button className='ql-clean' />
                      </div> */}
                      <ReactQuill
                        theme={quillData.theme}
                        onChange={(value, delta, source) => {
                          if (source === 'user')
                            handleChange(value, item.label);
                        }}
                        value={
                          item?.label === 'generalNotes'
                            ? data
                            : data[item.label]
                        }
                        modules={EditorModules}
                        formats={EditorFormats}
                        placeholder={'Type here'}
                        className={
                          item?.label === 'generalNotes'
                            ? classes.reactquill_bottom
                            : classes.reactquill_width
                        }
                        style={{
                          width: item?.label === 'generalNotes' && '100%',
                        }}
                      />
                    </Grid>
                  ) : (
                    <>
                      {/* <Grid
                        item
                        alignItems={'center'}
                        display={'flex'}
                        xs={12}
                        md={5}
                      >
                        <label className={classes.data_label}>
                          {item.title}
                        </label>
                      </Grid> */}
                      {viewMode &&
                      item.type === 'quill' &&
                      (item?.label === 'generalNotes'
                        ? data?.replace('<p><br></p>', '')
                        : data[item.label]?.replace('<p><br></p>', '')) ? (
                        <Grid
                          item
                          xs={12}
                          md={item?.title ? 7 : 12}
                          style={{
                            display: 'flex',
                            width: '100%',
                            // paddingRight: 10,
                          }}
                        >
                          <ReactQuill
                            theme={quillData.theme}
                            onChange={(value) =>
                              !viewMode && handleChange(value, item.label)
                            }
                            value={
                              item?.label === 'generalNotes'
                                ? data
                                : data[item.label]
                            }
                            // modules={{
                            //   toolbar: '#toolbar',
                            // }}
                            modules={{ toolbar: false }}
                            formats={EditorFormats}
                            // bounds={'.app'}
                            placeholder={'Type here'}
                            className={
                              viewMode
                                ? classes.customQuillContainer
                                : classes.reactquill_width
                            }
                            style={{
                              width: item?.label === 'generalNotes' && '97%',
                            }}
                            readOnly={viewMode}
                          />
                        </Grid>
                      ) : item.type !== 'quill' ? (
                        <Grid
                          item
                          xs={12}
                          md={7}
                          style={{
                            display: 'flex',
                            paddingRight: isMobileView && 0,
                          }}
                        >
                          {item.type === 'radio' ? (
                            <>
                              {item.radio.map((redios, index) => (
                                <div
                                  style={{ marginLeft: index === 0 ? 0 : 25 }}
                                >
                                  <CustomRadioButton
                                    value={redios.value}
                                    label={redios.label}
                                    checked={data[item.label]}
                                    onChange={(value) =>
                                      !viewMode &&
                                      handleChange(value, item.label)
                                    }
                                  />
                                </div>
                              ))}
                            </>
                          ) : item.type === 'dropdown' ? (
                            viewMode ? (
                              !isClient() ? (
                                <Link
                                  to={`/app/vendor`}
                                  state={{ id: data[item.label] }}
                                  style={{ fontSize: 14, color: '#0088CC' }}
                                  onClick={() => {
                                    setLocaleStorageVal('VendorData', {
                                      searchString: '',
                                    });
                                  }}
                                >
                                  {props?.vendorDdListObj[data[item.label]]}
                                </Link>
                              ) : (
                                <>
                                  {props?.vendorPhoneObj && (
                                    <p className={classes.client_name}>
                                      <span>
                                        {
                                          props?.vendorDdListObj[
                                            data[item.label]
                                          ]
                                        }
                                      </span>
                                      {props?.vendorPhoneObj &&
                                        props?.vendorPhoneObj[
                                          data[item.label]
                                        ] && (
                                          <span>
                                            {`, ${
                                              props?.vendorPhoneObj[
                                                data[item.label]
                                              ]
                                            }`}
                                          </span>
                                        )}
                                    </p>
                                  )}
                                  <p className={classes.client_name}>
                                    {!props?.vendorPhoneObj &&
                                      props?.vendorDdListObj[data[item.label]]}
                                  </p>
                                </>
                              )
                            ) : (
                              <Dropdown
                                name={item.label}
                                onChange={(value) => {
                                  handleChange(value, item.label);
                                }}
                                value={data[item.label]}
                                disabled={viewMode}
                                optionArray={props.vendorDdList}
                                searchable
                                containerClassName={classes.dropdown_width}
                                title={Strings.SELECT_AN_OPTION}
                              />
                            )
                          ) : item.type === 'tagsInput' ? (
                            <>
                              {viewMode ? (
                                <p className={classes.client_name}>
                                  {data[item.label] && data[item.label]?.length
                                    ? data[item.label]?.join(', ')
                                    : ''}
                                </p>
                              ) : (
                                <TagsInput
                                  selectedTags={(value) => {
                                    handleChange(value, item.label);
                                  }}
                                  placeholder={'Add Emails'}
                                  tags={data[item.label] || []}
                                  renderTag={renderTags}
                                  className={classNames(classes.tags_input, {
                                    [classes.view_tags_input]: viewMode,
                                  })}
                                  disabled={viewMode}
                                />
                              )}
                            </>
                          ) : (
                            <>
                              {viewMode ? (
                                <p
                                  style={{ marginLeft: 0 }}
                                  className={classNames(
                                    classes.client_name,
                                    classes.address
                                  )}
                                >
                                  {item.type === 'phone'
                                    ? formatingPhoneNumber(data[item.label])
                                    : data[item.label]}
                                </p>
                              ) : (
                                <div style={{ width: '100%' }}>
                                  {item.type === 'phone' ? (
                                    <div className={classes.phoneno_container}>
                                      {/* <CountryCodePicker
                                      className={classes.information_input}
                                      containerClassName={classNames(
                                        classes.information_input_container,
                                        classes.countryCodeContainer
                                      )}
                                      value={data[item.codeLabel]}
                                      onChange={(e) =>
                                        onChange(e.target.value, item.codeLabel)
                                      }
                                    /> */}
                                      <Textbox
                                        name={item.label}
                                        onChange={(e) =>
                                          handleChange(
                                            e.target.value,
                                            item.label
                                          )
                                        }
                                        value={data[item.label]}
                                        error={item.error}
                                        helperText={item.helperText}
                                        placeholder={
                                          item.placeholder
                                            ? item.placeholder
                                            : Strings.TYPE_HERE
                                        }
                                        className={classes.information_input}
                                      />
                                    </div>
                                  ) : (
                                    <Textbox
                                      name={item.label}
                                      onChange={(e) =>
                                        handleChange(e.target.value, item.label)
                                      }
                                      value={data[item.label]}
                                      error={item.error}
                                      helperText={item.helperText}
                                      placeholder={
                                        item.placeholder
                                          ? item.placeholder
                                          : Strings.TYPE_HERE
                                      }
                                      className={classes.information_input}
                                      containerClassName={
                                        classes.information_input_container
                                      }
                                    />
                                  )}
                                </div>
                              )}
                            </>
                          )}
                        </Grid>
                      ) : null}
                    </>
                  )}
                </Grid>
              )}
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

const InternalNotes = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [note, setNote] = useState('');
  const [image, setImage] = useState('');
  const [file, setFile] = useState(null);
  const [fileExtension, setFileExtension] = useState(null);
  const [loader, setLoader] = useState(false);
  const [downloadFileId, setDownloadFileId] = useState(null);
  const [openImageInfoDialog, setOpenImageInfoDialog] = useState(false);
  const [fetchedFileId, setFetchedFileId] = useState(null);
  const inputFile = useRef(null);

  const fetchFileIcon = (type) => {
    if (
      type === 'image' ||
      type === 'jpg' ||
      type === 'jpeg' ||
      type === 'gif' ||
      type === 'png' ||
      type === 'svg'
    )
      return icons(theme).imageIcon;
    else if (type === 'doc' || type === 'word' || type === 'docx')
      return icons(theme).docFileIcon;
    else if (type === 'audio' || type === 'mp3')
      return icons(theme).audioFileIcon;
    else if (type === 'video' || type === 'mov' || type === 'mp4')
      return icons(theme).videoFileIcon;
    else if (type === 'xlsx' || type === 'xls' || type === 'csv')
      return icons(theme).excelFileIcon;
    else if (type === 'ppt') return icons(theme).pptFileIcon;
    else if (type === 'zip' || type === 'rar') return icons(theme).zipFileIcon;
    else if (type === 'pdf') return icons(theme).pdfFileIcon;
    else return icons(theme).undefinedFileIcon;
  };

  const handleFileUpload = (files) => {
    setFile(files.target.files[0]);
    const image = URL.createObjectURL(files.target.files[0]);
    let newFileExtension = files.target.files[0]?.name?.split('.').pop();
    setFileExtension(newFileExtension);
    setImage(image);
    document.getElementById('fileInput').value = '';
  };

  const clearData = () => {
    setNote('');
    setImage('');
    setFile('');
  };

  useEffect(() => {
    if (!loader) {
      setDownloadFileId(null);
    }
  }, [loader]);

  const noteHandle = (e) => {
    setNote(e.target.value);
  };

  return (
    <div className={classes.notes_container}>
      <div className={classes.note_input_container}>
        <Textbox
          name='note'
          onChange={noteHandle}
          value={note}
          placeholder={Strings.TYPE_YOUR_NOTE}
          className={classNames(classes.information_input, classes.note_input)}
          containerClassName={classes.note_input_text_container}
          multiline
          rows={2}
        />
        <div className={classes.note_action_container}>
          {image && (
            <div className={classes.note_preview_image_container}>
              <div
                className={classes.close_icon_container}
                onClick={() => {
                  setFile(null);
                  setImage('');
                }}
              >
                <img
                  className={classes.close_icon}
                  src={icons(theme).closeIcon}
                  alt=''
                />
              </div>
              <img
                className={classes.note_preview_image}
                src={
                  fileExtension === 'jpg' ||
                  fileExtension === 'jpeg' ||
                  fileExtension === 'gif' ||
                  fileExtension === 'png' ||
                  fileExtension === 'svg'
                    ? image
                    : fetchFileIcon(fileExtension)
                }
                alt=''
              />
            </div>
          )}
          <input
            id='fileInput'
            style={{ display: 'none' }}
            ref={inputFile}
            onChange={(event) => handleFileUpload(event)}
            type='file'
            accept='*'
          />
          <img
            onClick={() => inputFile.current.click()}
            className={classes.browse_button}
            src={icons(theme).browseIcon}
            alt=''
          />
          <Button
            containerClassName={classes.note_send_button_container}
            className={classNames(
              classes.note_send_button,
              !note && classes.note_button_disable
            )}
            loading={props.loading}
            disabled={!note || props.loading}
            icon={icons(theme).sendIcon}
            onClick={() => props.onCreateNotes({ note, file, clearData })}
          />
        </div>
      </div>
      <div className={classes.notes_data_container}>
        {props.data.map((item, index) => (
          <div className={classes.notes_data}>
            <div className={classes.note_header}>
              <p
                className={classNames(
                  classes.information_title,
                  classes.note_title
                )}
              >
                {item.message}
              </p>
              {/* <img className={classes.more_icon} src={MoreIcon} alt='' /> */}
            </div>
            {item.attachment?.url && (
              <img
                className={classes.note_image}
                src={
                  item?.attachment?.type === 'image'
                    ? item.attachment?.url
                    : fetchFileIcon(
                        item?.attachment?.fileName?.split('.').pop()
                      )
                }
                style={{
                  height: item?.attachment?.type !== 'image' && 100,
                  cursor: 'pointer',
                }}
                alt=''
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setOpenImageInfoDialog(true);
                  setFetchedFileId(item.attachment._id);
                }}
              />
            )}
            <div className={classes.note_footer}>
              <img
                className={classes.clock_icon}
                src={icons(theme).noteClockIcon}
                alt=''
              />
              <p
                className={classNames(classes.client_name, classes.note_title)}
              >
                on {moment(item.createdAt).format('MMM D YYYY')} at{' '}
                {moment(item.createdAt).format('h:mmA')} by {item.author.name}
              </p>
              {item?.attachment?._id === downloadFileId && loader ? (
                <CircularProgress
                  style={{
                    color: theme.palette.background_button,
                    marginLeft: 'auto',
                  }}
                  size={15}
                />
              ) : (
                item?.attachment?.url && (
                  <CustomizedTooltips title={Strings.DOWNLOAD}>
                    <img
                      className={classes.download_icon}
                      src={icons(theme).downloadIcon}
                      alt=''
                      onClick={async () => {
                        setDownloadFileId(item?.attachment?._id);
                        await downloadFile(
                          `${item.attachment?.url}`,
                          item.attachment?.type,
                          item.attachment?.fileName,
                          setLoader
                        );
                      }}
                    />
                  </CustomizedTooltips>
                )
              )}
            </div>
          </div>
        ))}
      </div>
      {openImageInfoDialog && (
        <MediaDetails
          // fileInfo={fetchedFileId}
          fileId={fetchedFileId}
          onNewClose={() => {
            setOpenImageInfoDialog(false);
            setFetchedFileId(null);
          }}
          // isAddTagsRequired={props?.paramJobId ? false : true}
        />
      )}
    </div>
  );
};

const AddProperty = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const [openInformation, setOpenInformation] = useState([]);
  const [onDropEffect, setOnDropEffect] = useState(false);
  const inputFile = useRef(null);
  const { state, pathname } = useLocation();
  const [propertyInfo, setPropertyInfo] = useState({});
  const viewMode = !isClient()
    ? pathname === '/app/clients/view/property'
    : pathname === '/app/property';
  const editMode = !isClient()
    ? pathname === '/app/clients/edit/property'
    : pathname === '/app/property/edit';
  const addMode = pathname === '/app/clients/add/property';
  const [propertyList, setPropertyList] = useState([]);
  const [activeProperty, setActiveProperty] = useState('');
  const [activeImageTab, setActiveImageTab] = useState(
    isClient() ? 'jobs' : 'issues'
  );
  const [notesList, setNotesList] = useState([]);
  const [propertyIssuesList, setPropertyIssuesList] = useState([]);
  const [propertyJobsList, setPropertyJobsList] = useState([]);
  const [propertyMediaList, setPropertyMediaList] = useState([]);
  const [vendorDdList, setVendorDdList] = useState([]);
  const [vendorDdListObj, setVendorDdListObj] = useState({});
  const [addNewVendor, setAddNewVendor] = useState(false);
  const [arrivalsDeparturesList, setArrivalsDeparturesList] = useState([]);
  const [showArrivalDepartureDialog, setShowArrivalDepartureDialog] =
    useState(false);
  const [selectedArrivalDeparture, setSelectedArrivalDeparture] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [isShowMoreJunctures, setIsShowMoreJunctures] = useState(false);
  const [showDeleteJunctureModal, setShowDeleteJunctureModal] = useState(false);
  const [selectedJunctureId, setSelectedJunctureId] = useState('');
  const [showImageViewer, setShowImageViewer] = useState('');
  const [showCropper, setShowCropper] = useState(false);
  const [cropData, setCropData] = useState('');
  const [tempFile, setTempFile] = useState('');
  const [tempImage, setTempImage] = useState('');
  const [reportList, setReportList] = useState([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [vendorPhoneObj, setVendorPhoneObj] = useState({});
  // const [attachmentList, setAttachmentList] = useState([]);
  // const [isUploadAttachment, setIsUploadAttachment] = useState(false);
  const [programList, setProgramList] = useState([]);
  const [showMediaDialog, setShowMediaDialog] = useState(false);
  const [selectedImg, setSelectedImg] = useState(null);
  const [selectedJobId, setSelectedJobId] = useState('');
  const [openJobInfoDialog, setOpenJobInfoDialog] = useState(false);

  const alert = useAlert();
  const inputRef = useRef();
  const autocompleteRef = useRef();
  const googleMapsApi = useGoogleMapsApi();
  const attachmentFile = useRef(null);
  const dispatch = useDispatch();

  // ===== clear reducer data =====
  useEffect(() => {
    const clearData = () => {
      dispatch(setPropertyById(null));
      dispatch(setPropertyNotesList([]));
      dispatch(setJobsByProperty({}));
      dispatch(setIssuesByProperty({}));
      dispatch(setMediaByProperty([]));
    };
    return () => clearData();
  }, []);

  useEffect(() => {
    if (googleMapsApi) {
      autocompleteRef.current = new googleMapsApi.places.Autocomplete(
        inputRef.current
        // { types: ['(address)'] }
      );
      autocompleteRef.current.addListener('place_changed', () => {
        const place = autocompleteRef.current.getPlace();
        setFieldValue('address', place.formatted_address);
        // Do something with the resolved place here (ie store in redux state)
      });
    }
  }, [googleMapsApi, props.propertyData, pathname]);

  //==== delete Img API functionality =====
  const deleteMediaFromProperty = () => {
    let data = {
      property: activeProperty,
      fileId: selectedImg,
    };
    props.deletePropertyMedia(
      data,
      () => setShowMediaDialog(false),
      (err) => onError(err)
    );
  };

  const getPropertyList = (data) => {
    const newData = data?.map((item) => ({
      id: item._id,
      name: item.name,
      // badgeColor: Colors.TOMATO_RED,
    }));
    return newData;
  };

  useEffect(() => {
    if (isClient()) props.fetchClientAllProperty((err) => onError(err));
    if (!isClient()) props.fetchProgramList((err) => onError(err));
  }, []);

  useEffect(() => {
    const data = props.programListData ? props.programListData : [];
    const newData = data.map((item) => ({
      title: item.title,
      value: item._id,
    }));
    setProgramList(newData);
  }, [props.programListData]);

  useEffect(() => {
    // if (!isClient()) {
    props.fetchVendorDropDownList((err) => onError(err));
    // }
  }, [props.addVendorData]);

  useEffect(() => {
    if (props.vendorDropDownListData.length > 0) {
      let obj = {};
      let phoneObj = {};
      const newData =
        props.vendorDropDownListData.map((item) => {
          obj[item._id] = item.fullName;
          if (isClient())
            phoneObj[item._id] = formatingPhoneNumber(item?.phoneNo);
          return {
            title: item.fullName,
            value: item._id,
          };
        }) || [];
      setVendorDdList(newData);
      setVendorDdListObj(obj);
      setVendorPhoneObj(isClient() ? phoneObj : null);
    }
  }, [props.vendorDropDownListData]);

  // useEffect(() => {
  //   if (
  //     props?.propertyData?.vendorInfo &&
  //     Object.keys(props?.propertyData?.vendorInfo)
  //   ) {
  //     let obj = {};
  //     const newData =
  //       Object.values(props?.propertyData?.vendorInfo).map((item) => {
  //         obj[item._id] = item.phoneNo;
  //         return {
  //           title: item.phoneNo,
  //           value: item._id,
  //         };
  //       }) || [];

  //     setVendorPhoneObj(obj);
  //   }
  // }, [props?.propertyData?.vendorInfo]);

  useEffect(() => {
    if (state) {
      setActiveImageTab(state?.activeTab || isClient() ? 'jobs' : 'issues');
      if ((viewMode || editMode) && !isClient()) {
        const newData = getPropertyList(state.properties);
        setPropertyList(newData);
        setActiveProperty(state.selectedProperty);
      }
    } else {
      if (!isClient()) navigate(-1);
      // if (isClient()) props.fetchClientAllProperty((err) => onError(err));
    }
  }, [state]);

  useEffect(() => {
    if (isClient()) {
      const data = props.clientAllPropertyData
        ? props.clientAllPropertyData
        : [];
      const newData = getPropertyList(data);
      setPropertyList(newData);
      setActiveProperty(newData?.[0]?.id || '');
    }
  }, [props.clientAllPropertyData]);

  useEffect(() => {
    if (activeProperty && (viewMode || editMode)) {
      props.fetchPropertyById(activeProperty, (err) => onError(err));
      if (!isClient()) {
        props.fetchNotesList(activeProperty, (err) => onError(err));
        props.fetchJobsByProperty(activeProperty, (err) => onError(err));
      }
      props.fetchIssuesByProperty(activeProperty, (err) => onError(err));
      // props.fetchMediaByProperty(activeProperty, (err) => onError(err));
    }
  }, [activeProperty, props.updatePropertyData, props.deleteTaskData]);

  useEffect(() => {
    if (activeProperty && (viewMode || editMode)) {
      props.fetchMediaByProperty(activeProperty, (err) => onError(err));
    }
  }, [
    activeProperty,
    props.updatePropertyData,
    props.uploadPropertyMediaData,
    props.deletePropertyMediaData,
    props.updateFileDetailsData,
  ]);

  useEffect(() => {
    if (activeProperty && (viewMode || editMode) && !isClient()) {
      props.fetchJuncturesListByProperty(activeProperty, (err) => onError(err));
    }
  }, [
    activeProperty,
    props.addJunctureData,
    props.updateJunctureData,
    props.deleteJunctureData,
  ]);

  useEffect(() => {
    if (viewMode || editMode) {
      const data = props.propertyData ? props.propertyData : {};
      setPropertyInfo(data);
    }
  }, [props.propertyData]);

  const setFormFieldValues = (data) => {
    const {
      address,
      image,
      name,
      securityInfo,
      cable_and_Internet_Info,
      contactInfo,
      vendorInfo,
      HOAInfo,
      arrival_departure_preferences,
      generalNotes,
      programType,
    } = propertyInfo;

    setFieldValue('address', address);
    setFieldValue('image', image);
    setFieldValue('residenceName', name);
    setFieldValue('programType', programType?._id);
    setFieldValue('securityInfo', {
      alarmExist: securityInfo?.alarmExist || false,
      alarmCode: securityInfo?.alarmCode || '',
      alarmLocation: securityInfo?.alarmLocation || '',
      alarmNotes: securityInfo?.alarmNotes || '',
      alarmCompany: securityInfo?.alarmCompany?._id || '',
      alarmCompanyPhone:
        securityInfo?.alarmCompanyPhone?.slice(
          securityInfo.alarmCompanyPhone.length - 10
        ) || '',
      alarmPassword: securityInfo?.alarmPassword || '',
      alarmCompanyPhoneCountryCode:
        securityInfo?.alarmCompanyPhone?.slice(0, -10).substring(1) || '1',
      notes: securityInfo?.notes || '',
    });
    setFieldValue('propertyInfo', {
      securityGateCode: propertyInfo?.propertyInfo?.securityGateCode || '',
      keyId: propertyInfo?.propertyInfo?.keyId || '',
      securityGateContact:
        propertyInfo?.propertyInfo?.securityGateContact?.slice(
          propertyInfo?.propertyInfo.securityGateContact.length - 10
        ) || '',
      securityGateContactCountryCode:
        propertyInfo?.propertyInfo?.securityGateContact
          ?.slice(0, -10)
          .substring(1) || '1',
      garageCode: propertyInfo?.propertyInfo?.garageCode || '',
      accessCodes: propertyInfo?.propertyInfo?.accessCodes || '',
      trashRecyclingPickup:
        propertyInfo?.propertyInfo?.trashRecyclingPickup || '',
      waterValveLocation: propertyInfo?.propertyInfo?.waterValveLocation || '',
      iceMakerLocation: propertyInfo?.propertyInfo?.iceMakerLocation || '',
      HVACSettings: propertyInfo?.propertyInfo?.HVACSettings || '',
      pool_Spa_Preferences:
        propertyInfo?.propertyInfo?.pool_Spa_Preferences || '',
      notes: propertyInfo?.propertyInfo?.notes || '',
      keyRequired:
        propertyInfo?.propertyInfo?.keyRequired === true
          ? true
          : propertyInfo?.propertyInfo?.keyRequired === false
          ? false
          : '',
      garbageAccessDoorLocked:
        propertyInfo?.propertyInfo?.garbageAccessDoorLocked || false,
      stormPrep: propertyInfo?.propertyInfo?.stormPrep || '',
      vehicleNotes: propertyInfo?.propertyInfo?.vehicleNotes || '',
      waterHeaterLocation:
        propertyInfo?.propertyInfo?.waterHeaterLocation || '',
    });
    setFieldValue('cable_and_Internet_Info', {
      internetProvider: cable_and_Internet_Info?.internetProvider || '',
      accountNumber: cable_and_Internet_Info?.accountNumber || '',
      wifiName: cable_and_Internet_Info?.wifiName || '',
      wifiPassword: cable_and_Internet_Info?.wifiPassword || '',
      cableProvider: cable_and_Internet_Info?.cableProvider || '',
      cable_account_number: cable_and_Internet_Info?.cable_account_number || '',
      notes: cable_and_Internet_Info?.notes || '',
    });
    setFieldValue('contactInfo', {
      clientName: contactInfo?.clientName || '',
      contactPhone:
        contactInfo?.contactPhone?.slice(
          contactInfo?.contactPhone.length - 10
        ) || '',
      contactPhoneCountryCode:
        contactInfo?.contactPhone?.slice(0, -10).substring(1) || '1',
      contactEmail: contactInfo?.contactEmail || '',
      spouseName: contactInfo?.spouseName || '',
      spousePhone:
        contactInfo?.spousePhone?.slice(contactInfo?.spousePhone.length - 10) ||
        '',
      spousePhoneCountryCode:
        contactInfo?.spousePhoneCountryCode?.slice(0, -10).substring(1) || '1',
      spouseEmail: contactInfo?.spouseEmail || '',
      northernAddress: contactInfo?.northernAddress || '',
      notes: contactInfo?.notes || '',
    });
    setFieldValue('vendorInfo', {
      cleaningService: vendorInfo?.cleaningService?._id || '',
      poolCompany: vendorInfo?.poolCompany?._id || '',
      ACCompany: vendorInfo?.ACCompany?._id || '',
      landscapeCompany: vendorInfo?.landscapeCompany?._id || '',
      AVCompany: vendorInfo?.AVCompany?._id || '',
      interiorPestControl: vendorInfo?.interiorPestControl?._id || '',
      exteriorPestControl: vendorInfo?.exteriorPestControl?._id || '',
      generatorCompany: vendorInfo?.generatorCompany?._id || '',
      gasCompany: vendorInfo?.gasCompany?._id || '',
      elevatorCompany: vendorInfo?.elevatorCompany?._id || '',
      otherVendor1: vendorInfo?.otherVendor1?._id || '',
      otherVendor2: vendorInfo?.otherVendor2?._id || '',
      otherVendor3: vendorInfo?.otherVendor3?._id || '',
      otherVendor4: vendorInfo?.otherVendor4?._id || '',
      otherVendor5: vendorInfo?.otherVendor5?._id || '',
      notes: vendorInfo?.notes || '',
    });
    setFieldValue('HOAInfo', {
      communityName: HOAInfo?.communityName || '',
      managerName: HOAInfo?.managerName || '',
      managerEmail: HOAInfo?.managerEmail || '',
      managerContact:
        HOAInfo?.managerContact?.slice(HOAInfo?.managerContact.length - 10) ||
        '',
      managerContactCountryCode:
        HOAInfo?.managerContact?.slice(0, -10).substring(1) || '1',
      notes: HOAInfo?.notes || '',
    });
    setFieldValue('arrival_departure_preferences', {
      arrivalPreferences:
        arrival_departure_preferences?.arrivalPreferences || '',
      departurePreferences:
        arrival_departure_preferences?.departurePreferences || '',
      notes: arrival_departure_preferences?.notes || '',
    });
    setFieldValue('generalNotes', generalNotes);
  };

  useEffect(() => {
    if (viewMode || editMode) {
      setFormFieldValues();
    }
  }, [propertyInfo]);

  useEffect(() => {
    if (!isClient()) {
      const data = props.notesListData ? props.notesListData : [];
      setNotesList(data);
    }
  }, [props.notesListData]);

  useEffect(() => {
    const data = props.propertyIssuesData ? props.propertyIssuesData : [];
    setPropertyIssuesList(data);
  }, [props.propertyIssuesData]);

  useEffect(() => {
    const data = props.propertyJobsData ? props.propertyJobsData : [];
    setPropertyJobsList(data);
  }, [props.propertyJobsData]);

  useEffect(() => {
    if (cropData) {
      validateImageAfterCropped();
    }
  }, [cropData]);

  useEffect(() => {
    if (isClient() && activeProperty) {
      props.fetchJobList(activeProperty, (err) => onError(err));
    }
  }, [activeProperty]);

  useEffect(() => {
    const data = props.propertyMediaData ? props.propertyMediaData : [];
    setPropertyMediaList(data);
  }, [props.propertyMediaData]);

  useEffect(() => {
    if (isClient()) {
      // const newReportData = props?.jobListData?.jobs || [];
      // const mapedReportData = structuredClone(newReportData)?.filter(
      //   (el) => el?.property?._id === activeProperty
      // );
      setReportList({ ...props?.jobListData });
    }
  }, [props.jobListData, activeProperty]);

  useEffect(() => {
    const data = props.juncturesListByProperty
      ? props.juncturesListByProperty
      : [];
    setArrivalsDeparturesList(data);
  }, [props.juncturesListByProperty]);

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  const onSuccess = (msg) => {
    if (msg) {
      alert.success(msg);
    }
  };

  const onSelectFile = () => {
    inputFile.current.click();
  };

  const onFileUpload = (res) => {
    if (res) {
      let data = res[0];
      if (data?.type) delete data?.type;
      setFieldValue('image', data);
    }
  };

  const handleFileUpload = (files, input) => {
    setShowCropper(true);
    let newFiles;
    if (input === 'input') {
      newFiles = files.target.files;
    } else {
      newFiles = files;
    }
    const image = URL.createObjectURL(newFiles[0]);
    setTempImage(image);
    setTempFile(newFiles[0]);
    setOnDropEffect(false);
  };

  const formik = useFormik({
    initialValues: {
      residenceName: '',
      address: '',
      image: {},
      programType: '',
      securityInfo: {
        alarmExist: true,
        alarmCode: '',
        alarmLocation: '',
        alarmNotes: '',
        alarmCompany: '',
        alarmCompanyPhone: '',
        alarmPassword: '',
        alarmCompanyPhoneCountryCode: '1',
        notes: '',
      },
      propertyInfo: {
        securityGateCode: '',
        keyId: '',
        securityGateContact: '',
        securityGateContactCountryCode: '1',
        garageCode: '',
        accessCodes: '',
        trashRecyclingPickup: '',
        waterValveLocation: '',
        iceMakerLocation: '',
        notes: '',
        keyRequired: '',
        garbageAccessDoorLocked: false,
        stormPrep: '',
        waterHeaterLocation: '',
        vehicleNotes: '',
      },
      cable_and_Internet_Info: {
        internetProvider: '',
        accountNumber: '',
        wifiName: '',
        wifiPassword: '',
        cableProvider: '',
        cable_account_number: '',
        notes: '',
      },
      contactInfo: {
        clientName: '',
        contactPhone: '',
        contactPhoneCountryCode: '1',
        contactEmail: [],
        spouseName: '',
        spousePhone: '',
        spousePhoneCountryCode: '1',
        spouseEmail: '',
        northernAddress: '',
        notes: '',
      },
      vendorInfo: {
        cleaningService: '',
        poolCompany: '',
        ACCompany: '',
        landscapeCompany: '',
        AVCompany: '',
        interiorPestControl: '',
        exteriorPestControl: '',
        generatorCompany: '',
        gasCompany: '',
        elevatorCompany: '',
        otherVendor1: '',
        otherVendor2: '',
        otherVendor3: '',
        otherVendor4: '',
        otherVendor5: '',
        notes: '',
      },
      HOAInfo: {
        communityName: '',
        managerName: '',
        managerEmail: '',
        managerContact: '',
        managerContactCountryCode: '1',
        notes: '',
      },
      arrival_departure_preferences: {
        arrivalPreferences: '',
        departurePreferences: '',
        notes: '',
      },
      generalNotes: '',
    },
    validationSchema: AddPropertySchema,

    onSubmit: (values) => {
      setHasUnsavedChanges(false);
      handleAddEditProperty(values);
    },
  });

  const validateImageAfterCropped = async () => {
    if (formik.values.image?.url) {
      const data = {
        url: formik.values.image?.url,
      };
      await props.deleteFile(data, (err) => onError(err));
    }
    fetch(cropData)
      .then((res) => res.blob())
      .then(async (blob) => {
        const file = new File([blob], tempFile.name, {
          type: tempFile.type,
        });
        let data = {
          files: [file],
        };
        props.uploadFile(
          data,
          (res) => onFileUpload(res),
          (err) => onError(err)
        );
        setTempFile('');
        setTempImage('');
      });
  };

  const handleAddEditProperty = (values) => {
    const data = {
      name: values.residenceName,
      address: values.address,
      image: values.image,
      programType: values?.programType,
      securityInfo: {
        alarmExist: values.securityInfo.alarmExist,
        alarmCode: values.securityInfo.alarmCode || '',
        alarmLocation: values.securityInfo.alarmLocation || '',
        alarmNotes: values.securityInfo.alarmNotes || '',
        alarmCompanyPhone:
          (values.securityInfo.alarmCompanyPhone &&
            '+' +
              values.securityInfo.alarmCompanyPhoneCountryCode +
              values.securityInfo.alarmCompanyPhone) ||
          '',
        alarmPassword: values.securityInfo.alarmPassword || '',
        notes: values.securityInfo.notes?.replace('<p><br></p>', '') || '',
      },
      propertyInfo: {
        securityGateCode: values?.propertyInfo?.securityGateCode || '',
        keyId: values?.propertyInfo?.keyId || '',
        securityGateContact:
          (values?.propertyInfo?.securityGateContact &&
            '+' +
              values?.propertyInfo?.securityGateContactCountryCode +
              values?.propertyInfo?.securityGateContact) ||
          '',
        garageCode: values?.propertyInfo?.garageCode || '',
        accessCodes: values?.propertyInfo?.accessCodes || '',
        trashRecyclingPickup: values?.propertyInfo?.trashRecyclingPickup || '',
        waterValveLocation: values?.propertyInfo?.waterValveLocation || '',
        iceMakerLocation: values?.propertyInfo?.iceMakerLocation || '',
        HVACSettings: values?.propertyInfo?.HVACSettings || '',
        pool_Spa_Preferences: values?.propertyInfo?.pool_Spa_Preferences || '',
        notes: values?.propertyInfo?.notes?.replace('<p><br></p>', '') || '',
        // keyRequired: values.propertyInfo.keyRequired,
        garbageAccessDoorLocked:
          values?.propertyInfo?.garbageAccessDoorLocked ?? false,
        stormPrep:
          values?.propertyInfo?.stormPrep?.replace('<p><br></p>', '') || '',
        vehicleNotes:
          values?.propertyInfo?.vehicleNotes?.replace('<p><br></p>', '') || '',
        waterHeaterLocation:
          values?.propertyInfo?.waterHeaterLocation?.replace(
            '<p><br></p>',
            ''
          ) || '',
      },
      cable_and_Internet_Info: {
        internetProvider: values.cable_and_Internet_Info.internetProvider || '',
        accountNumber: values.cable_and_Internet_Info.accountNumber || '',
        wifiName: values.cable_and_Internet_Info.wifiName || '',
        wifiPassword: values.cable_and_Internet_Info.wifiPassword || '',
        cableProvider: values.cable_and_Internet_Info.cableProvider || '',
        cable_account_number:
          values.cable_and_Internet_Info.cable_account_number || '',
        notes:
          values.cable_and_Internet_Info.notes?.replace('<p><br></p>', '') ||
          '',
      },
      contactInfo: {
        clientName: values.contactInfo.clientName || '',
        contactPhone:
          (values.contactInfo.contactPhone &&
            '+' +
              values.contactInfo.contactPhoneCountryCode +
              values.contactInfo.contactPhone) ||
          '',
        contactEmail: values.contactInfo.contactEmail || [],
        spouseName: values.contactInfo.spouseName || '',
        spousePhone:
          (values.contactInfo.spousePhone &&
            '+' +
              values.contactInfo.spousePhoneCountryCode +
              values.contactInfo.spousePhone) ||
          '',
        spouseEmail: values.contactInfo.spouseEmail || '',
        northernAddress: values.contactInfo.northernAddress || '',
        notes: values.contactInfo.notes?.replace('<p><br></p>', '') || '',
      },
      HOAInfo: {
        communityName: values.HOAInfo.communityName || '',
        managerName: values.HOAInfo.managerName || '',
        managerEmail: values.HOAInfo.managerEmail || '',
        managerContact:
          (values.HOAInfo.managerContact &&
            '+' +
              values.HOAInfo.managerContactCountryCode +
              values.HOAInfo.managerContact) ||
          '',
        notes: values.HOAInfo.notes?.replace('<p><br></p>', '') || '',
      },
      arrival_departure_preferences: {
        arrivalPreferences:
          values.arrival_departure_preferences.arrivalPreferences?.replace(
            '<p><br></p>',
            ''
          ) || '',
        departurePreferences:
          values.arrival_departure_preferences.departurePreferences?.replace(
            '<p><br></p>',
            ''
          ) || '',
        notes:
          values.arrival_departure_preferences.notes?.replace(
            '<p><br></p>',
            ''
          ) || '',
      },
      generalNotes: values.generalNotes?.replace('<p><br></p>', ''),
    };

    if (Object.keys(values?.vendorInfo).length > 0) {
      let obj = values?.vendorInfo;
      Object.keys(obj).forEach((key) => {
        if (obj[key] === '' || obj[key] === null || obj[key] === undefined) {
          delete obj[key];
        }
      });
      let vendorInfo = {};
      for (let a in obj) {
        vendorInfo[a] = obj[a];
      }
      vendorInfo.notes = vendorInfo?.notes?.replace('<p><br></p>', '');
      data.vendorInfo = vendorInfo;
    }
    if (values?.securityInfo?.alarmCompany) {
      data.securityInfo = {
        ...data.securityInfo,
        alarmCompany: values?.securityInfo?.alarmCompany,
      };
    }

    if (
      values?.propertyInfo?.keyRequired === true ||
      values?.propertyInfo?.keyRequired === false
    ) {
      data.propertyInfo.keyRequired = values?.propertyInfo?.keyRequired;
    }

    // vendorInfo: {
    //   cleaningService: values.vendorInfo.cleaningService || '',
    //   poolCompany: values.vendorInfo.poolCompany || '',
    //   ACCompany: values.vendorInfo.ACCompany || '',
    //   landscapeCompany: values.vendorInfo.landscapeCompany || '',
    //   AVCompany: values.vendorInfo.AVCompany || '',
    //   interiorPestControl: values.vendorInfo.interiorPestControl || '',
    //   exteriorPestControl: values.vendorInfo.exteriorPestControl || '',
    //   otherVendor1: values.vendorInfo.otherVendor1 || '',
    //   otherVendor2: values.vendorInfo.otherVendor2 || '',
    //   otherVendor3: values.vendorInfo.otherVendor3 || '',
    //   notes: values.vendorInfo.notes || '',
    // },
    if (editMode) {
      props.updateProperty(
        propertyInfo._id,
        data,
        () => {
          if (!isClient()) {
            const newProperty = state.properties;
            const propertyIndex = newProperty.findIndex((item) => {
              return item._id === activeProperty;
            });
            if (propertyIndex !== -1) {
              newProperty[propertyIndex].name = values.residenceName;
            }
            navigate('/app/clients/view/property', {
              state: {
                programType: state.programType,
                properties: newProperty,
                selectedProperty: activeProperty,
              },
            });
          } else {
            navigate('/app/property', {
              state: {
                programType: state.programType,
                selectedProperty: activeProperty,
              },
            });
          }
        },
        (err) => onError(err)
      );
    } else {
      data.client = state._id;
      // data.programType = state.programType._id;
      props.createProperty(
        data,
        (err) => onError(err),
        () => navigate(-1)
      );
    }
  };

  const { errors, touched, values, handleSubmit, handleChange, setFieldValue } =
    formik;

  const onSuccessAddNotes = (res, clearData) => {
    setNotesList([...notesList, res.data.data]);
    clearData();
  };

  const addNewNotes = (data) => {
    const newData = {
      message: data.note,
    };
    if (data.file) newData.attachment = data.file;
    props.createNotes(
      propertyInfo._id,
      newData,
      (res) => onSuccessAddNotes(res, data.clearData),
      (err) => onError(err)
    );
  };

  const openGoogleMap = (address) => {
    window.open(`https://www.google.com/maps/search/${address}`);
  };

  const tabsArray = () => {
    return isClient()
      ? [
          // { id: 'jobs', name: 'Jobs', length: propertyJobsList.length || 0 },
          {
            id: 'jobs',
            name: 'Report',
            length: reportList?.totalRecords || 0,
          },
          {
            id: 'issues',
            name: 'Tasks',
            length: propertyIssuesList?.totalRecords || 0,
          },
          {
            id: 'media',
            name: 'Media',
            length: propertyMediaList.length || 0,
          },
        ]
      : [
          {
            id: 'issues',
            name: 'Tasks',
            length: propertyIssuesList?.totalRecords || 0,
          },
          {
            id: 'jobs',
            name: 'Jobs',
            length: propertyJobsList?.totalRecords || 0,
          },
          {
            id: 'internalNotes',
            name: 'Internal Notes',
            length: notesList.length || 0,
          },
          {
            id: 'media',
            name: 'Media',
            length: propertyMediaList.length || 0,
          },
          {
            id: 'arrivals-departures',
            name: 'Arrivals & Departures',
            length: arrivalsDeparturesList.length || 0,
          },
        ];
  };

  // ===== if change/refresh page asking for save data. =====
  useEffect(() => {
    const confirmationMessage = 'Please save before you leave.';
    const handleBeforeUnload = (event) => {
      if (hasUnsavedChanges && editMode) {
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };

    const handleNavigate = (event) => {
      if (hasUnsavedChanges && editMode) {
        const isConfirmed = window.confirm(confirmationMessage);
        if (isConfirmed) {
          // setPropertyInfo(props?.propertyData);
          event.preventDefault();
          return;
        }
        // setHasUnsavedChanges(false);
      }
    };

    const handlePopState = (event) => {
      if (hasUnsavedChanges && editMode) {
        const confirmed = window.confirm(confirmationMessage);
        if (confirmed) navigate(1);
        else setFormFieldValues();
      }
    };

    // Add event listener for beforeunload
    window.addEventListener('beforeunload', handleBeforeUnload, {
      passive: true,
    });

    // Add event listener for popstate (browser back arrow)
    window.addEventListener('popstate', handlePopState, { passive: true });

    // Add event listener for navigation through side menu or dynamically
    const sideMenuLinks = document.querySelectorAll('.side-menu-item');
    sideMenuLinks.forEach((link) => {
      link.addEventListener('click', handleNavigate, { passive: true });
    });

    return () => {
      // Remove event listener for beforeunload
      window.removeEventListener('beforeunload', handleBeforeUnload, {
        passive: true,
      });

      // Remove event listener for popstate (browser back arrow)
      window.removeEventListener('popstate', handlePopState, { passive: true });

      // Remove event listener for navigation through side menu
      sideMenuLinks.forEach((link) => {
        link.removeEventListener('click', handleNavigate, { passive: true });
      });
    };
  }, [hasUnsavedChanges, editMode]);

  const handleBackIconNavigation = () => {
    isClient()
      ? navigate('/app/property', {
          state: {
            selectedProperty: activeProperty,
          },
        })
      : navigate('/app/clients/view/property', {
          state: {
            programType: state.programType,
            properties: state.properties,
            selectedProperty: activeProperty,
          },
        });
  };

  const handleBackIconClick = () => {
    if (editMode && hasUnsavedChanges) {
      const confirmationMessage = 'Please save before you leave.';
      const handleNavigate = (event) => {
        if (hasUnsavedChanges && editMode) {
          const isConfirmed = window.confirm(confirmationMessage);
          if (isConfirmed) {
            event.preventDefault();
            window.removeEventListener('click', handleNavigate, {
              passive: true,
            });
            return;
          } else {
            setHasUnsavedChanges(false);
            handleBackIconNavigation();
            setFormFieldValues();
            window.removeEventListener('click', handleNavigate, {
              passive: true,
            });
          }
        }
      };

      window.addEventListener('click', handleNavigate, { passive: true });
    } else if (editMode && !hasUnsavedChanges) {
      handleBackIconNavigation();
    } else navigate('/app/clients');
  };

  // ===== Form fields arrays =====
  const securityInfoValues = [
    {
      label: 'alarmExist',
      type: 'radio',
      title: 'Alarm',
      radio: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ],
    },
    {
      label: 'alarmCode',
      type: 'text',
      title: 'Alarm code',
    },
    {
      label: 'alarmLocation',
      type: 'text',
      title: 'Alarm Panel Location(s)',
    },
    {
      label: 'alarmNotes',
      type: 'text',
      title: 'Alarm Notes',
    },
    {
      label: 'alarmCompany',
      type: 'dropdown',
      title: 'Alarm Company Name',
    },
    {
      label: 'alarmCompanyPhone',
      codeLabel: 'alarmCompanyPhoneCountryCode',
      type: 'phone',
      title: 'Alarm Company Phone',
      error: Boolean(
        touched.securityInfo &&
          touched.securityInfo.alarmCompanyPhone &&
          touched.securityInfo.alarmCompanyPhone &&
          errors.securityInfo &&
          errors.securityInfo.alarmCompanyPhone &&
          errors.securityInfo.alarmCompanyPhone
      ),
      helperText:
        touched.securityInfo &&
        touched.securityInfo.alarmCompanyPhone &&
        touched.securityInfo.alarmCompanyPhone &&
        errors.securityInfo &&
        errors.securityInfo.alarmCompanyPhone &&
        errors.securityInfo.alarmCompanyPhone,
    },
    {
      label: 'alarmPassword',
      type: 'text',
      title: 'Alarm Password',
    },
    {
      label: 'notes',
      type: 'quill',
      title: 'Security Information Notes',
    },
  ];

  const propertyInfoValues = [
    {
      label: 'keyRequired',
      type: 'radio',
      title: 'Key Required',
      radio: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ],
    },
    {
      label: 'garbageAccessDoorLocked',
      type: 'radio',
      title: 'Garage Access Door',
      radio: [
        {
          label: 'Locked',
          value: true,
        },
        {
          label: 'Unlocked',
          value: false,
        },
      ],
    },
    {
      label: 'keyId',
      type: 'text',
      title: 'Key ID',
    },
    {
      label: 'securityGateCode',
      type: 'text',
      title: 'Security gate code',
    },

    {
      label: 'securityGateContact',
      codeLabel: 'securityGateContactCountryCode',
      type: 'phone',
      title: 'Security gate contact',
      error: Boolean(
        touched.propertyInfo &&
          touched.propertyInfo.securityGateContact &&
          touched.propertyInfo.securityGateContact &&
          errors.propertyInfo &&
          errors.propertyInfo.securityGateContact &&
          errors.propertyInfo.securityGateContact
      ),
      helperText:
        touched.propertyInfo &&
        touched.propertyInfo.securityGateContact &&
        touched.propertyInfo.securityGateContact &&
        errors.propertyInfo &&
        errors.propertyInfo.securityGateContact &&
        errors.propertyInfo.securityGateContact,
    },
    {
      label: 'garageCode',
      type: 'text',
      title: 'Garage code',
    },
    {
      label: 'accessCodes',
      type: 'text',
      title: 'Access code(s) to the home (if applicable)',
    },
    {
      label: 'trashRecyclingPickup',
      type: 'text',
      title: 'Trash & Recycling Pickup',
    },
    {
      label: 'waterValveLocation',
      type: 'text',
      title: 'Water Valve Location',
    },
    {
      label: 'waterHeaterLocation',
      type: 'quill',
      title: 'Water Heater Location(s)',
    },
    {
      label: 'iceMakerLocation',
      type: 'text',
      title: 'Ice Maker Location(s)',
    },
    {
      label: 'HVACSettings',
      type: 'text',
      title: 'HVAC Settings',
    },
    {
      label: 'stormPrep',
      type: 'quill',
      title: 'Storm Prep',
    },
    {
      label: 'pool_Spa_Preferences',
      type: 'text',
      title: 'Pool & Spa Preferences',
    },
    {
      label: 'vehicleNotes',
      type: 'quill',
      title: 'Vehicle Notes',
    },
    {
      label: 'notes',
      type: 'quill',
      title: 'Property Information Notes',
    },
  ];

  const cableInternetInfoValues = [
    {
      label: 'internetProvider',
      type: 'text',
      title: 'Internet Provider (company)',
    },
    {
      label: 'accountNumber',
      type: 'text',
      title: 'Account #',
    },
    {
      label: 'wifiName',
      type: 'text',
      title: 'Wi-Fi Network Name',
    },
    {
      label: 'wifiPassword',
      type: 'text',
      title: 'Wi-Fi Network Password',
    },
    {
      label: 'cableProvider',
      type: 'text',
      title: 'Cable Provider (company)',
    },
    {
      label: 'cable_account_number',
      type: 'text',
      title: 'Cable Account #',
    },
    {
      label: 'notes',
      type: 'quill',
      title: 'Cable and Internet Information Notes',
    },
  ];

  const contactInfoValues = [
    {
      label: 'clientName',
      type: 'text',
      title: 'Client Name',
    },
    {
      label: 'contactPhone',
      codeLabel: 'contactPhoneCountryCode',
      type: 'phone',
      title: 'Contact Phone',
      error: Boolean(
        touched.contactInfo &&
          touched.contactInfo.contactPhone &&
          touched.contactInfo.contactPhone &&
          errors.contactInfo &&
          errors.contactInfo.contactPhone &&
          errors.contactInfo.contactPhone
      ),
      helperText:
        touched.contactInfo &&
        touched.contactInfo.contactPhone &&
        touched.contactInfo.contactPhone &&
        errors.contactInfo &&
        errors.contactInfo.contactPhone &&
        errors.contactInfo.contactPhone,
    },
    {
      label: 'contactEmail',
      type: 'tagsInput',
      title: 'Contact Email',
      error: Boolean(
        touched.contactInfo &&
          touched.contactInfo.contactEmail &&
          touched.contactInfo.contactEmail &&
          errors.contactInfo &&
          errors.contactInfo.contactEmail &&
          errors.contactInfo.contactEmail
      ),
      helperText:
        touched.contactInfo &&
        touched.contactInfo.contactEmail &&
        touched.contactInfo.contactEmail &&
        errors.contactInfo &&
        errors.contactInfo.contactEmail &&
        errors.contactInfo.contactEmail,
    },
    {
      label: 'spouseName',
      type: 'text',
      title: 'Spouse Name',
    },
    {
      label: 'spousePhone',
      codeLabel: 'spousePhoneCountryCode',
      type: 'phone',
      title: 'Spouse Phone',
      error: Boolean(
        touched.contactInfo &&
          touched.contactInfo.spousePhone &&
          touched.contactInfo.spousePhone &&
          errors.contactInfo &&
          errors.contactInfo.spousePhone &&
          errors.contactInfo.spousePhone
      ),
      helperText:
        touched.contactInfo &&
        touched.contactInfo.spousePhone &&
        touched.contactInfo.spousePhone &&
        errors.contactInfo &&
        errors.contactInfo.spousePhone &&
        errors.contactInfo.spousePhone,
    },
    {
      label: 'spouseEmail',
      type: 'text',
      title: 'Spouse Email',
      error: Boolean(
        touched.contactInfo &&
          touched.contactInfo.spouseEmail &&
          touched.contactInfo.spouseEmail &&
          errors.contactInfo &&
          errors.contactInfo.spouseEmail &&
          errors.contactInfo.spouseEmail
      ),
      helperText:
        touched.contactInfo &&
        touched.contactInfo.spouseEmail &&
        touched.contactInfo.spouseEmail &&
        errors.contactInfo &&
        errors.contactInfo.spouseEmail &&
        errors.contactInfo.spouseEmail,
    },
    {
      label: 'northernAddress',
      type: 'text',
      title: 'Northern Address',
    },
    {
      label: 'notes',
      type: 'quill',
      title: 'Contact Information Notes',
    },
  ];

  const vendorInfoValues = [
    {
      label: 'cleaningService',
      type: 'dropdown',
      title: 'Cleaning Service',
    },
    {
      label: 'poolCompany',
      type: 'dropdown',
      title: 'Pool Company',
    },
    {
      label: 'ACCompany',
      type: 'dropdown',
      title: 'AC Company',
    },
    {
      label: 'landscapeCompany',
      type: 'dropdown',
      title: 'Landscape Company',
    },
    {
      label: 'AVCompany',
      type: 'dropdown',
      title: 'AV company',
    },
    {
      label: 'interiorPestControl',
      type: 'dropdown',
      title: 'Pest Control (Interior)',
    },
    {
      label: 'exteriorPestControl',
      type: 'dropdown',
      title: 'Pest Control (Exterior)',
    },
    {
      label: 'generatorCompany',
      type: 'dropdown',
      title: 'Generator Company',
    },
    {
      label: 'gasCompany',
      type: 'dropdown',
      title: 'Gas Company',
    },
    {
      label: 'elevatorCompany',
      type: 'dropdown',
      title: 'Elevator Company',
    },
    {
      label: 'otherVendor1',
      type: 'dropdown',
      title: 'Other',
    },
    {
      label: 'otherVendor2',
      type: 'dropdown',
      title: 'Other',
    },
    {
      label: 'otherVendor3',
      type: 'dropdown',
      title: 'Other',
    },
    {
      label: 'otherVendor4',
      type: 'dropdown',
      title: 'Other',
    },
    {
      label: 'otherVendor5',
      type: 'dropdown',
      title: 'Other',
    },
    {
      label: 'notes',
      type: 'quill',
      title: 'Vendor Information Notes',
    },
  ];

  const hoaInfoValues = [
    {
      label: 'communityName',
      type: 'text',
      title: 'Community Name',
    },
    {
      label: 'managerName',
      type: 'text',
      title: `HOA Manager's Name`,
    },
    {
      label: 'managerEmail',
      type: 'text',
      title: `HOA Manager's Email`,
      error: Boolean(
        touched.HOAInfo &&
          touched.HOAInfo.managerEmail &&
          touched.HOAInfo.managerEmail &&
          errors.HOAInfo &&
          errors.HOAInfo.managerEmail &&
          errors.HOAInfo.managerEmail
      ),
      helperText:
        touched.HOAInfo &&
        touched.HOAInfo.managerEmail &&
        touched.HOAInfo.managerEmail &&
        errors.HOAInfo &&
        errors.HOAInfo.managerEmail &&
        errors.HOAInfo.managerEmail,
    },
    {
      label: 'managerContact',
      codeLabel: 'managerContactCountryCode',
      type: 'phone',
      title: `HOA Manager's Phone`,
      error: Boolean(
        touched.HOAInfo &&
          touched.HOAInfo.managerContact &&
          touched.HOAInfo.managerContact &&
          errors.HOAInfo &&
          errors.HOAInfo.managerContact &&
          errors.HOAInfo.managerContact
      ),
      helperText:
        touched.HOAInfo &&
        touched.HOAInfo.managerContact &&
        touched.HOAInfo.managerContact &&
        errors.HOAInfo &&
        errors.HOAInfo.managerContact &&
        errors.HOAInfo.managerContact,
    },
    {
      label: 'notes',
      type: 'quill',
      title: 'Community/HOA Information Notes',
    },
  ];

  const arrivalDepartureInfoValues = [
    {
      label: 'arrivalPreferences',
      type: 'quill',
      title: 'Arrival Preferences',
    },
    {
      label: 'departurePreferences',
      type: 'quill',
      title: 'Departure Preferences',
    },
    {
      label: 'notes',
      type: 'quill',
      title: 'Additional Notes',
    },
  ];

  const generalNoteInfoValues = [
    {
      label: 'generalNotes',
      type: 'quill',
      title: '',
    },
  ];

  const handleOnChange = useCallback(
    debounce((value, field, mainField) => {
      if (field === 'generalNotes') {
        setFieldValue(field, value);
      } else {
        setFieldValue(mainField, {
          ...values[mainField],
          [field]: value,
        });
      }
      setHasUnsavedChanges(true);
    }, 300),
    [values]
  );

  const propertyData = () => {
    return (
      <div className={classes.bottom_container}>
        {/* ===== Security Info ===== */}
        <Information
          title={Strings.SECURITY_INFO}
          setOpenInformation={setOpenInformation}
          openInformation={openInformation}
          data={values.securityInfo}
          viewMode={viewMode}
          vendorDdList={vendorDdList}
          vendorDdListObj={vendorDdListObj}
          vendorPhoneObj={vendorPhoneObj}
          dataInformation={securityInfoValues}
          onChange={(value, field) =>
            handleOnChange(value, field, 'securityInfo')
          }
        />
        {/* ===== Property Info ===== */}
        {((viewMode &&
          propertyInfo.propertyInfo &&
          JSON.stringify(propertyInfo.propertyInfo) !== '{}') ||
          !viewMode) && (
          <Information
            title={Strings.PROPERTY_INFO}
            setOpenInformation={setOpenInformation}
            openInformation={openInformation}
            data={values.propertyInfo}
            viewMode={viewMode}
            dataInformation={propertyInfoValues}
            onChange={(value, field) =>
              handleOnChange(value, field, 'propertyInfo')
            }
          />
        )}
        {/* ===== Cable & Internet Info ===== */}
        {((viewMode &&
          propertyInfo.cable_and_Internet_Info &&
          JSON.stringify(propertyInfo.cable_and_Internet_Info) !== '{}') ||
          !viewMode) && (
          <Information
            title={Strings.CABLE_INTERNET_INFO}
            setOpenInformation={setOpenInformation}
            openInformation={openInformation}
            data={values.cable_and_Internet_Info}
            viewMode={viewMode}
            dataInformation={cableInternetInfoValues}
            onChange={(value, field) =>
              handleOnChange(value, field, 'cable_and_Internet_Info')
            }
          />
        )}
        {/* ===== Contact Info ===== */}
        {((viewMode &&
          propertyInfo.contactInfo &&
          JSON.stringify(propertyInfo.contactInfo) !== '{}') ||
          !viewMode) && (
          <Information
            title={Strings.CONTACT_INFO}
            setOpenInformation={setOpenInformation}
            openInformation={openInformation}
            data={values.contactInfo}
            viewMode={viewMode}
            dataInformation={contactInfoValues}
            onChange={(value, field) =>
              handleOnChange(value, field, 'contactInfo')
            }
          />
        )}
        {/* ===== Vendor Info ===== */}
        {((viewMode &&
          propertyInfo.vendorInfo &&
          JSON.stringify(propertyInfo.vendorInfo) !== '{}') ||
          !viewMode) && (
          <Information
            title={Strings.VENDOR_INFO}
            setOpenInformation={setOpenInformation}
            openInformation={openInformation}
            data={values.vendorInfo}
            viewMode={viewMode}
            vendorDdList={vendorDdList}
            vendorDdListObj={vendorDdListObj}
            vendorPhoneObj={vendorPhoneObj}
            addVendor
            setAddNewVendor={setAddNewVendor}
            dataInformation={vendorInfoValues}
            onChange={(value, field) =>
              handleOnChange(value, field, 'vendorInfo')
            }
          />
        )}
        {/* ===== HOA Info ===== */}
        {((viewMode &&
          propertyInfo.HOAInfo &&
          JSON.stringify(propertyInfo.HOAInfo) !== '{}') ||
          !viewMode) && (
          <Information
            title={Strings.HOA_INFO}
            setOpenInformation={setOpenInformation}
            openInformation={openInformation}
            data={values.HOAInfo}
            viewMode={viewMode}
            dataInformation={hoaInfoValues}
            onChange={(value, field) => handleOnChange(value, field, 'HOAInfo')}
          />
        )}
        {/* ===== Arrival & Departure info ===== */}
        {((viewMode &&
          propertyInfo.arrival_departure_preferences &&
          JSON.stringify(propertyInfo.arrival_departure_preferences) !==
            '{}') ||
          !viewMode) && (
          <Information
            title={Strings.ARRIVAL_DEPARTURE_PREFERENCES}
            setOpenInformation={setOpenInformation}
            openInformation={openInformation}
            data={values.arrival_departure_preferences}
            viewMode={viewMode}
            dataInformation={arrivalDepartureInfoValues}
            onChange={(value, field) =>
              handleOnChange(value, field, 'arrival_departure_preferences')
            }
          />
        )}
        {/* ===== general Note Info ===== */}
        {((viewMode &&
          propertyInfo.generalNotes &&
          JSON.stringify(propertyInfo.generalNotes) !== '{}') ||
          !viewMode) && (
          <Information
            title={Strings.GENERAL_PROPERTY_NOTES}
            setOpenInformation={setOpenInformation}
            openInformation={openInformation}
            data={values.generalNotes}
            viewMode={viewMode}
            dataInformation={generalNoteInfoValues}
            onChange={(value) => handleOnChange(value, 'generalNotes')}
          />
        )}
      </div>
    );
  };

  const ArrivalsAndDeparturesListView = (props) => {
    const classes = useStyles();
    let showData;
    if (props.isShowMoreJunctures) {
      showData = props.dataList;
    } else {
      showData = props.dataList?.slice(0, 3);
    }
    return (
      <>
        {showData?.map((data, index) => (
          <div className={classes.upcoming_data_container} key={index}>
            <Stack direction='row' alignItems='center'>
              <div className={classes.flex_column_view} style={{ flex: 1 }}>
                <div className={classes.flex_view}>
                  <img src={icons().arrivalIcon} alt='' />
                  <span className={classes.text_bold}>{Strings.ARRIVAL}</span>
                </div>
                <span className={classes.text_blue}>
                  {data?.arrival
                    ? moment(data?.arrival).format('MMM DD, YYYY')
                    : 'TBD'}
                </span>
              </div>
              <div className={classes.flex_column_view} style={{ flex: 1 }}>
                <div className={classes.flex_view}>
                  <img src={icons().departuresIcon} alt='' />
                  <span className={classes.text_bold}>
                    {Strings.DEPARTURES}
                  </span>
                </div>
                <span className={classes.text_red}>
                  {data?.departure
                    ? moment(data?.departure).format('MMM DD, YYYY')
                    : 'TBD'}
                </span>
              </div>
              <div className={classes.icon_container}>
                <CustomizedTooltips title={'edit'}>
                  <img
                    className={classes.action_icons}
                    src={icons(theme).editIcon}
                    alt=''
                    onClick={(e) => {
                      setIsEdit(true);
                      setSelectedArrivalDeparture(data);
                      setShowArrivalDepartureDialog(true);
                    }}
                  />
                </CustomizedTooltips>
                <CustomizedTooltips title={'delete'}>
                  <img
                    className={classes.action_icons}
                    src={icons(theme).deleteIcon}
                    alt=''
                    onClick={(e) => {
                      props.setSelectedJunctureId(data._id);
                      props.setShowDeleteJunctureModal(true);
                    }}
                  />
                </CustomizedTooltips>
              </div>
            </Stack>
            <Stack gap={1.25}>
              {data?.arrivalNotes ? (
                <Stack gap={0.5}>
                  <Typography
                    lineHeight='normal'
                    className={classes.arrialDepartureTextBold}
                  >
                    {Strings.ARRIVAL_NOTES}
                  </Typography>
                  <Typography className={classes.arrivalSubText}>
                    {data?.arrivalNotes}
                  </Typography>
                </Stack>
              ) : null}
              {data?.departureNotes ? (
                <Stack gap={0.5}>
                  <Typography
                    lineHeight='normal'
                    className={classes.arrialDepartureTextBold}
                  >
                    {Strings.DEPARTURE_NOTES}
                  </Typography>
                  <Typography className={classes.arrivalSubText}>
                    {data?.departureNotes}
                  </Typography>
                </Stack>
              ) : null}
            </Stack>
          </div>
        ))}
        {props.dataList.length > 3 && (
          <div className={classes.view_more_container}>
            <span
              className={classes.text_blue}
              onClick={() =>
                props.setIsShowMoreJunctures(!props.isShowMoreJunctures)
              }
            >
              {props.isShowMoreJunctures
                ? Strings.VIEW_LESS
                : Strings.VIEW_MORE}
            </span>
          </div>
        )}
      </>
    );
  };

  // const onUploadAttachmentFiles = (data) => {
  //   const userName = localStorage?.userName || '';
  //   const fetchedData = data?.map((el) => ({
  //     ...el,
  //     title: formik.values.residenceName,
  //     createdAt: new Date(),
  //     createdBy: userName,
  //   }));
  //   setPropertyMediaList([...propertyMediaList, ...fetchedData]);
  //   setAttachmentList([...data, ...attachmentList]);
  //   setIsUploadAttachment(false);
  // };

  const uploadAttachmentFiles = (files) => {
    let newFiles = files.target.files;
    const data = {
      client: propertyInfo?.client?._id,
      property: propertyInfo?._id,
      files: newFiles ? [...newFiles] : [],
    };
    props.uploadPropertyMedia(data, onError);
    // setIsUploadAttachment(true);
    // props.uploadFile(
    //   data,
    //   (res) => onUploadAttachmentFiles(res),
    //   (err) => onError(err),
    // );
  };

  // const addMedia = () => {
  //   const data = {
  //     client: propertyInfo?.client?._id,
  //     property: propertyInfo?._id,
  //     files: attachmentList,
  //   };
  //   props.uploadPropertyMedia(data, onError);
  //   setAttachmentList([]);
  // };

  const onSuccessDeleteJuncture = async () => {
    await props.deleteJuncture(
      selectedJunctureId,
      () => {
        setShowDeleteJunctureModal(false);
        onSuccess('SuccessFully Deleted');
        props.fetchJuncturesList((err) => onError(err));
      },
      onError
    );
  };

  const handleJobClick = (jobId) => {
    if (isClient() && jobId) {
      navigate('/app/report', {
        state: { jobId },
      });
    }
    setOpenJobInfoDialog(true);
    setSelectedJobId(jobId);
  };

  return (
    <div className={classes.container}>
      {isClient() && props.clientAllPropertyLoading ? (
        <div
          className={classes.flexView}
          style={{
            width: '100%',
            height: '100%',
            marginTop: 0,
            backgroundColor: theme.palette.background,
          }}
        >
          <CircularProgress
            style={{
              color: theme.palette.background_button,
            }}
          />
        </div>
      ) : isClient() &&
        props.clientAllPropertyData &&
        props.clientAllPropertyData.length === 0 ? (
        <div
          className={classes.flexView}
          style={{
            width: '100%',
            height: '100%',
            marginTop: 0,
            backgroundColor: theme.palette.background,
          }}
        >
          <div className={classes.flexView} style={{ marginTop: 0 }}>
            <p className={classes.no_data_message}>
              {Strings.NO_PROPERTY_FOUND}
            </p>
          </div>
        </div>
      ) : (
        <FormikProvider value={formik}>
          <Form
            className={classes.form_container}
            autoComplete='off'
            noValidate
            onSubmit={handleSubmit}
          >
            <div className={classes.left_container}>
              <TitleContainer
                containerClassName={classes.title_container}
                title={Strings.PROPERTY_DETAILS}
              />
              <div className={classes.top_container}>
                {!(isClient() && viewMode) && (
                  <img
                    src={icons(theme).backIcon}
                    alt=''
                    className={classes.back_icon}
                    onClick={handleBackIconClick}
                  />
                )}
                {viewMode ? (
                  <Tabs
                    dataList={propertyList}
                    onClick
                    noDataLength
                    underlineTabs
                    containerClassName={classes.property_tab_container}
                    tabClassName={classes.property_tab}
                    activeTab={activeProperty}
                    onChange={(value) => setActiveProperty(value)}
                  />
                ) : (
                  <>
                    <Textbox
                      name='residenceName'
                      onChange={handleChange('residenceName')}
                      value={values.residenceName}
                      error={Boolean(
                        touched.residenceName && errors.residenceName
                      )}
                      helperText={touched.residenceName && errors.residenceName}
                      placeholder={Strings.RESIDENCE_NAME}
                      labelClassName={classes.form_label}
                      className={classes.residence_input}
                      containerClassName={classes.residence_input_container}
                      disabled={isClient()}
                    />
                    <Hidden mdDown>
                      <Button
                        type='submit'
                        text={
                          editMode ? Strings.SAVE_CHANGES : Strings.ADD_PROPERTY
                        }
                        containerClassName={classes.add_new_container}
                        className={classes.add_new_button}
                        loading={
                          editMode
                            ? props.updatePropertyLoading
                            : props.addPropertyLoading
                        }
                        disabled={
                          editMode
                            ? props.updatePropertyLoading
                            : props.addPropertyLoading
                        }
                      />
                    </Hidden>
                  </>
                )}
              </div>
              {((isClient() && editMode) || (!isClient() && !viewMode)) && (
                <Hidden mdUp>
                  <Button
                    type='submit'
                    text={
                      editMode ? Strings.SAVE_CHANGES : Strings.ADD_PROPERTY
                    }
                    containerClassName={classes.add_new_container}
                    className={classes.add_new_button}
                    loading={
                      editMode
                        ? props.updatePropertyLoading
                        : props.addPropertyLoading
                    }
                    disabled={
                      editMode
                        ? props.updatePropertyLoading
                        : props.addPropertyLoading
                    }
                  />
                </Hidden>
              )}
              {props.propertyLoading ? (
                <div className={classes.flexView}>
                  <CircularProgress
                    style={{
                      color: theme.palette.background_button,
                    }}
                  />
                </div>
              ) : (
                <>
                  <div className={classes.center_container}>
                    <div className={classes.client_details}>
                      <Grid
                        container
                        spacing={2}
                        style={{ alignItems: 'center' }}
                      >
                        <Grid item xs={6} lg={2.4} order={{ xs: 3, sm: 1 }}>
                          <div className={classes.client_name_container}>
                            <label
                              htmlFor='CLIENT'
                              className={classes.client_name_label}
                            >
                              {Strings.CLIENT.toUpperCase()}
                            </label>
                            <label
                              htmlFor='client-name'
                              className={classes.client_name}
                            >
                              {viewMode || editMode
                                ? propertyInfo?.client?.name
                                : state?.name}
                            </label>
                          </div>
                        </Grid>
                        {viewMode && (
                          <Grid item xs={6} lg={2.4} order={{ xs: 4, sm: 2 }}>
                            <div className={classes.client_name_container}>
                              <label
                                htmlFor='added_on'
                                className={classes.client_name_label}
                              >
                                {Strings.ADDED_ON}
                              </label>
                              <label
                                htmlFor='client-name'
                                className={classes.client_name}
                              >
                                {moment(propertyInfo?.createdAt).format(
                                  'MMM DD, YYYY'
                                )}
                              </label>
                            </div>
                          </Grid>
                        )}
                        {viewMode && !isClient() && (
                          <Grid item xs={6} lg={2} order={{ xs: 5, sm: 3 }}>
                            <div style={{ display: 'flex' }}>
                              <div
                                style={{
                                  marginRight: 0,
                                  border: 'none',
                                  backgroundColor:
                                    propertyInfo?.userAvailability === 'Away'
                                      ? Colors.LIGHT_GREEN
                                      : Colors.TOMATO_RED,
                                  color: Colors.WHITE,
                                }}
                                className={classes.program_container}
                              >
                                <label htmlFor='program'>
                                  {propertyInfo?.userAvailability}
                                </label>
                              </div>
                            </div>
                          </Grid>
                        )}
                        <Grid item xs={6} lg={2.8} order={{ xs: 1, sm: 4 }}>
                          <div style={{ display: 'flex' }}>
                            {viewMode ? (
                              <div
                                style={{ marginRight: 0 }}
                                className={classes.program_container}
                              >
                                <label htmlFor='program'>
                                  {viewMode || editMode
                                    ? propertyInfo?.programType?.title
                                    : state?.programType?.title}
                                </label>
                              </div>
                            ) : (
                              <Dropdown
                                name='programType'
                                onChange={(value) => {
                                  setFieldValue('programType', value);
                                  setHasUnsavedChanges(true);
                                }}
                                value={values.programType}
                                label={Strings.PROGRAM_TYPE}
                                error={Boolean(
                                  touched.programType && errors.programType
                                )}
                                helperText={
                                  touched.programType && errors.programType
                                }
                                title={Strings.TYPE_OR_SELECT}
                                labelClassName={classes.form_label}
                                // className={classes.form_input}
                                containerClassName={
                                  classes.form_input_container
                                }
                                optionArray={programList}
                                searchable
                              />
                            )}
                          </div>
                        </Grid>
                        {viewMode && !isClient() && (
                          <Grid item xs={6} lg={2.4} order={{ xs: 2, sm: 5 }}>
                            <Button
                              text={Strings.EDIT}
                              containerClassName={classes.edit_button_container}
                              className={classes.add_new_button}
                              onClick={() =>
                                navigate(
                                  !isClient()
                                    ? '/app/clients/edit/property'
                                    : '/app/property/edit',
                                  {
                                    state: isClient()
                                      ? {
                                          selectedProperty: activeProperty,
                                        }
                                      : {
                                          programType: state.programType,
                                          properties: state.properties,
                                          selectedProperty: activeProperty,
                                        },
                                  }
                                )
                              }
                              // onClick={() =>
                              //   navigate('/app/clients/edit/property', {
                              //     state: {
                              //       programType: state.programType,
                              //       properties: state.properties,
                              //       selectedProperty: activeProperty,
                              //     },
                              //   })
                              // }
                              // loading={props.addPropertyLoading}
                              // disabled={props.addPropertyLoading}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </div>
                  </div>
                  <Hidden smDown>{propertyData()}</Hidden>
                </>
              )}
            </div>
            <div className={classes.right_container}>
              {props.propertyLoading ? (
                <div className={classes.flexView}>
                  <CircularProgress
                    style={{
                      color: theme.palette.background_button,
                    }}
                  />
                </div>
              ) : (
                <>
                  {viewMode || isClient() ? (
                    <div
                      className={classes.add_image_container}
                      style={{ border: 'none', cursor: 'pointer' }}
                      onClick={() => {
                        if (values.image?.url) {
                          setShowImageViewer(values.image?.url);
                        }
                      }}
                    >
                      {values?.image?.url ? (
                        <LazyLoadImage
                          effect='opacity'
                          placeholderSrc={icons().loading}
                          wrapperClassName={classes.backGround_image}
                          src={values.image?.url}
                          style={{ height: '100%', objectFit: 'contain' }}
                          alt=''
                        />
                      ) : (
                        <div>Image Not Found</div>
                      )}
                    </div>
                  ) : (
                    <FileDrop
                      onDragOver={(event) =>
                        !props.uploadFileLoading &&
                        event &&
                        setOnDropEffect(true)
                      }
                      onDragLeave={(event) =>
                        !props.uploadFileLoading &&
                        event &&
                        setOnDropEffect(false)
                      }
                      onDrop={(files) =>
                        !props.uploadFileLoading && handleFileUpload(files)
                      }
                    >
                      <div
                        className={classNames(
                          classes.add_image_container,
                          onDropEffect && classes.add_image_drop_effect
                        )}
                      >
                        {values.image?.url && (
                          <img
                            src={values.image?.url}
                            style={{
                              height: '100%',
                              objectFit: 'contain',
                              borderRadius: 8,
                            }}
                            alt=''
                          />
                        )}
                        {props.uploadFileLoading ? (
                          <CircularProgress
                            style={{
                              color: theme.palette.background_button,
                              position: 'absolute',
                            }}
                          />
                        ) : (
                          <div
                            style={{
                              position: 'absolute',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <input
                              style={{ display: 'none' }}
                              ref={inputFile}
                              onChange={(event) =>
                                handleFileUpload(event, 'input')
                              }
                              type='file'
                              accept='image/*'
                            />
                            <div
                              className={classes.image_container}
                              onClick={onSelectFile}
                            >
                              <img
                                src={icons(theme).gallery}
                                alt=''
                                className={classes.gallery_icon}
                              />
                            </div>
                            <label
                              onClick={onSelectFile}
                              className={classes.common_label}
                            >
                              {Strings.ADD_IMAGE}
                            </label>
                          </div>
                        )}
                      </div>
                    </FileDrop>
                  )}
                  {viewMode || isClient() ? (
                    <div
                      className={classNames(
                        classes.input_container,
                        classes.address_container
                      )}
                      onClick={() => openGoogleMap(values.address)}
                    >
                      <img
                        className={classes.address_icon}
                        src={icons(theme).locationIcon}
                        alt=''
                      />
                      <p
                        className={classNames(
                          classes.client_name,
                          classes.address
                        )}
                      >
                        {values.address}
                      </p>
                    </div>
                  ) : (
                    <Textbox
                      type='text'
                      name='address'
                      label={Strings.ADDRESS}
                      placeholder={Strings.TYPE_HERE}
                      onChange={handleChange('address')}
                      value={values.address}
                      error={Boolean(touched.address && errors.address)}
                      helperText={touched.address && errors.address}
                      labelClassName={classes.data_label}
                      className={classes.information_input}
                      containerClassName={classes.input_container}
                      inputRef={inputRef}
                    />
                  )}
                  {!addMode && (
                    <>
                      <Tabs
                        dataList={tabsArray()}
                        underlineTabs
                        activeTabUnderline
                        activeTab={activeImageTab}
                        onChange={(value) => setActiveImageTab(value)}
                        containerClassName={classes.image_tab_container}
                      />
                      {activeImageTab === 'media' && (
                        <div className={classes.addAttachment_container}>
                          <label className={classes.allFiles_text}>
                            {`All files (${propertyMediaList?.length})`}
                          </label>
                          {!isClient() && (
                            <div
                              className={classes.flex_row}
                              style={{ flex: 'inherit' }}
                            >
                              {props.uploadPropertyMediaLoading ? (
                                <CircularProgress
                                  style={{
                                    color: theme.palette.background_button,
                                  }}
                                  size={20}
                                />
                              ) : (
                                <div
                                  className={
                                    classes.addAttachment_text_container
                                  }
                                  onClick={() => attachmentFile.current.click()}
                                >
                                  <input
                                    style={{ display: 'none' }}
                                    ref={attachmentFile}
                                    onChange={(e) => uploadAttachmentFiles(e)}
                                    type='file'
                                    accept='*'
                                    multiple
                                    disabled={props.uploadPropertyMediaLoading}
                                  />
                                  <img
                                    src={icons(theme).attachmentIcon}
                                    alt=''
                                  />
                                  <label className={classes.common_label}>
                                    {Strings.ADD_ATTACHMENTS}
                                  </label>
                                </div>
                              )}
                              {/* <Button
                                text={Strings.ADD}
                                containerClassName={
                                  classes.addAttachment_button_container
                                }
                                className={classes.add_attachment_button}
                                loading={props.uploadPropertyMediaLoading}
                                disabled={
                                  props.uploadFileLoading ||
                                  props.uploadPropertyMediaLoading ||
                                  attachmentList?.length === 0
                                }
                                onClick={() => addMedia()}
                              /> */}
                            </div>
                          )}
                        </div>
                      )}
                      <div className={classes.tabs_data_container}>
                        {activeImageTab === 'internalNotes' && (
                          <InternalNotes
                            onCreateNotes={addNewNotes}
                            loading={props.addNotesLoading}
                            data={[...notesList].reverse()}
                          />
                        )}
                        {activeImageTab === 'issues' && (
                          <div className={classes.data_container}>
                            <IssuesContainer
                              data={propertyIssuesList?.issues || []}
                              limit={5}
                              propertyId={activeProperty}
                            />
                          </div>
                        )}
                        {activeImageTab === 'jobs' && (
                          <div className={classes.data_container}>
                            <JobsContainer
                              data={
                                isClient()
                                  ? reportList?.report
                                  : propertyJobsList?.jobs || []
                              }
                              handleJobClick={handleJobClick}
                              limit={5}
                              propertyId={activeProperty}
                            />
                          </div>
                        )}
                        {activeImageTab === 'media' && (
                          <div className={classes.data_container}>
                            <MediaImageContainer
                              imageArray={
                                propertyMediaList?.map((item) => {
                                  return {
                                    ...item,
                                    title: item?.property?.name || item?.title,
                                    // clientName: item?.client?.name,
                                    createdBy:
                                      item?.addedBy?.name || item?.createdBy,
                                    createdAt: item?.createdAt,
                                    url: item?.url,
                                  };
                                }) || []
                              }
                              // imageData={imageData}
                              itemsPerRow={2}
                              showImageText
                              loading={props.propertyMediaLoading}
                              isAddProperty={true}
                              isImgDelete={!isClient()}
                              propertyData={activeProperty}
                              onClickDeleteMedia={(e, val) => {
                                setShowMediaDialog(true);
                                setSelectedImg(val._id);
                              }}
                            />
                          </div>
                        )}
                        {activeImageTab === 'arrivals-departures' && (
                          <div className={classes.data_container}>
                            <div
                              className={classes.add_new_text}
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                setShowArrivalDepartureDialog(true);
                              }}
                            >
                              {Strings.ADD_NEW}
                            </div>
                            {props.juncturesListByPropertyLoading ? (
                              <div className={classes.flexView}>
                                <CircularProgress
                                  style={{
                                    color: theme.palette.background_button,
                                  }}
                                />
                              </div>
                            ) : (
                              <ArrivalsAndDeparturesListView
                                dataList={arrivalsDeparturesList || []}
                                isShowMoreJunctures={isShowMoreJunctures}
                                setIsShowMoreJunctures={setIsShowMoreJunctures}
                                setShowDeleteJunctureModal={
                                  setShowDeleteJunctureModal
                                }
                                setSelectedJunctureId={setSelectedJunctureId}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
            {!props.propertyLoading && (
              <Hidden smUp>
                <div className={classes.property_data_container}>
                  {propertyData()}
                </div>
              </Hidden>
            )}
          </Form>
        </FormikProvider>
      )}
      {addNewVendor && (
        <NewVendor
          onNewClose={() => {
            setAddNewVendor(false);
          }}
          isEdit={false}
        />
      )}
      {showArrivalDepartureDialog && (
        <NewArrivalDeparture
          isEdit={isEdit}
          propertyId={propertyInfo._id}
          arrivalDepartureData={selectedArrivalDeparture}
          onNewClose={() => {
            setShowArrivalDepartureDialog(false);
            setIsEdit(false);
          }}
        />
      )}
      {showDeleteJunctureModal && (
        <CommonDialog
          title={Strings.DELETE_ARRIVAL_DEPARTURE}
          message={Strings.ARE_YOU_SURE_DELETE_ARRIVAL_DEPARTURE}
          onSuccess={() => onSuccessDeleteJuncture()}
          onClose={() => {
            setShowDeleteJunctureModal(false);
          }}
          loading={props.deleteJunctureLoading}
        />
      )}
      {showImageViewer !== '' && (
        <ImageViewer
          onClose={() => setShowImageViewer('')}
          image={showImageViewer}
        />
      )}
      {showCropper && (
        <ImageCropper
          url={tempImage}
          onClose={() => {
            setShowCropper(false);
          }}
          setCropData={setCropData}
        />
      )}

      {showMediaDialog && (
        <CommonDialog
          title={'Media'}
          message={'Are you sure you want to delete this media?'}
          onSuccess={() => deleteMediaFromProperty()}
          onClose={() => {
            setShowMediaDialog(false);
          }}
          loading={props.deletePropertyMediaLoading}
        />
      )}

      {openJobInfoDialog && (
        <JobDetails
          onNewClose={() => {
            setOpenJobInfoDialog(false);
            setSelectedJobId('');
          }}
          fetchJobId={selectedJobId}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  addPropertyData: state.addPropertyData.data,
  addPropertyLoading: state.addPropertyData.loading,

  propertyData: state.propertyData.data,
  propertyLoading: state.propertyData.loading,

  updatePropertyData: state.updatePropertyData.data,
  updatePropertyLoading: state.updatePropertyData.loading,

  uploadFileLoading: state.uploadFileData.loading,

  addNotesData: state.addNotesData.data,
  addNotesLoading: state.addNotesData.loading,

  notesListData: state.notesListData.data,
  notesListLoading: state.notesListData.loading,

  propertyIssuesData: state.propertyIssues.data,
  propertyIssuesLoading: state.propertyIssues.loading,

  propertyJobsData: state.propertyJobs.data,
  propertyJobsLoading: state.propertyJobs.loading,

  propertyMediaData: state.propertyMedia.data,
  propertyMediaLoading: state.propertyMedia.loading,

  clientAllPropertyData: state.clientAllPropertyData.data,
  clientAllPropertyLoading: state.clientAllPropertyData.loading,

  vendorDropDownListData: state.vendorDropDownListData.data,

  juncturesListByProperty: state.juncturesListByProperty.data,
  juncturesListByPropertyLoading: state.juncturesListByProperty.loading,

  addJunctureData: state.addJunctureData.data,

  updateJunctureData: state.updateJunctureData.data,

  uploadPropertyMediaLoading: state.uploadPropertyMediaData.loading,
  uploadPropertyMediaData: state.uploadPropertyMediaData.data,

  addVendorData: state.addVendorData.data,

  deleteJunctureData: state.deleteJunctureData.data,
  deleteJunctureLoading: state.deleteJunctureData.loading,

  jobListData: state.jobListData.data,
  jobListLoading: state.jobListData.loading,

  programListData: state.programListData.data,
  programListLoading: state.programListData.loading,

  deleteTaskLoading: state.deleteIssueData.loading,
  deleteTaskData: state.deleteIssueData.data,

  deletePropertyMediaData: state.deletePropertyMediaData.data,
  deletePropertyMediaLoading: state.deletePropertyMediaData.loading,

  updateFileDetailsData: state.updateFileDetailsData.data,
});

export default connect(mapStateToProps, {
  createProperty,
  fetchPropertyById,
  uploadFile,
  deleteFile,
  updateProperty,
  createNotes,
  fetchNotesList,
  fetchIssuesByProperty,
  fetchJobsByProperty,
  fetchClientAllProperty,
  fetchMediaByProperty,
  fetchVendorDropDownList,
  fetchJuncturesListByProperty,
  uploadPropertyMedia,
  deleteJuncture,
  fetchJobList,
  fetchProgramList,
  deletePropertyMedia,
})(AddProperty);
